import { Button, Menu, Popover, Position } from 'evergreen-ui'
import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { HeaderContext } from '.'
import myTheme, { breakpoints } from '../../styles/theme'
import { GUIDES } from '../../utils/constants'

const useStyles = createUseStyles({
  navButtons: {
    background: `${myTheme.colors.blue}`,
    border: 'none',
    boxShadow: 'none',
    fontFamily: 'Roboto',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: '30px',
    '&:hover, &:active': {
      backgroundImage: 'none !important',
      boxShadow: 'none !important'
    },
    [`@media (max-width:${breakpoints.md})`]: {
      fontSize: '14px',
      marginRight: '0px !important',
      marginTop: '5px',
      marginBottom: '5px',
      backgroundColor: 'transparent',
      color: myTheme.colors.labelDark,
      fontWeight: '500',
      margin: 0,
      padding: 0
    }
  },
  menuNavButton: {
    composes: '$navButton',
    width: '100% !important',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    fontSize: '14px',
    '&:hover, &:active, &:focus': {
      background: 'transparent !important',
      boxShadow: 'none !important'
    }
  }
})

const ResourcesMobile = ({ handleRealmResourceChange }) => {
  const classes = useStyles()
  const { resources } = useContext(HeaderContext)

  if (!resources) {
    return null
  }
  return (
    <Menu.Group title="Resources">
      {resources?.map(resource => {
        const options = resource.resourcePageCollection?.items || []
        return (
          <Menu.Item key={resource.title} onClick={() => ''}>
            {options?.length > 0 ? (
              <Popover
                position={Position.LEFT}
                content={
                  <Menu>
                    <Menu.Group>
                      {options?.map(option => (
                        <Menu.Item
                          key={option.sys.id}
                          onClick={() =>
                            handleRealmResourceChange({
                              value:
                                resource.title === GUIDES
                                  ? `guides?id="${option.sys.id}"`
                                  : `resources?id="${option.sys.id}"`
                            })
                          }
                        >
                          {option.title}
                        </Menu.Item>
                      ))}
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button className={classes.menuNavButton}>
                  {resource.title}
                </Button>
              </Popover>
            ) : (
              <p>{resource.title}</p>
            )}
          </Menu.Item>
        )
      })}
    </Menu.Group>
  )
}

export default ResourcesMobile
