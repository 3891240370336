import React from 'react'
import { Button, Heading, Pane, toaster } from 'evergreen-ui'
import LoadingSpinner from './LoadingSpinner'
import Modal from './Modal'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../styles/sharedComponentStyles'
import { useMutation } from '@apollo/client'
import { logout } from '../utils/auth'
import { ACCEPT_TERMS_AND_CONDITIONS_MUTATION } from '../mutations/acceptTermsAndConditions'
import { useGlobalStore } from '../hooks/useGlobalStore'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  buttons: {
    marginTop: '50px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
})

const UPDATE_TERMS_AND_CONDITIONS_ERROR_MESSAGE =
  'Sorry, something went wrong updating the terms and conditions data, please try again later.'

const TermsAndConditions = ({
  showDialog,
  setShowDialog,
  loading,
  termsAndConditionsDoc
}) => {
  const sharedClasses = useSharedStyles()
  const { userData } = useGlobalStore()
  const [acceptTermsAndConditions, { loading: acceptLoading }] = useMutation(
    ACCEPT_TERMS_AND_CONDITIONS_MUTATION,
    {
      onCompleted: result => {
        if (result?.update_User?.returning?.[0]) {
          setShowDialog(false)
        }
      },
      onError: _ => toaster.danger(UPDATE_TERMS_AND_CONDITIONS_ERROR_MESSAGE)
    }
  )

  const termsAndConditions =
    termsAndConditionsDoc?.content?.json?.content?.[0]?.content?.[0]?.value

  const handleLogout = e => {
    e.preventDefault()
    logout()
  }

  const handleAgree = () => {
    acceptTermsAndConditions({
      variables: {
        userId: userData?.id,
        version: termsAndConditionsDoc?.sys?.publishedVersion
      }
    })
  }

  return (
    <Modal showDialog={showDialog} hasClose={false} width="90%">
      <Modal.Body>
        {loading && <LoadingSpinner />}
        <Pane height="400px" overflow="auto" padding="30px">
          <Heading size={800} is="h2" textAlign="center">
            Our Terms and Conditions have been updated.
          </Heading>
          <Pane is="p" marginTop="50px" textAlign="justify">
            {termsAndConditions}
          </Pane>
          <Pane is="p" marginTop="50px">
            I have read and agree to the TabCAT terms and conditions as written
            above.
          </Pane>
        </Pane>
        <Pane className={sharedClasses.buttons}>
          <Button
            className={sharedClasses.buttonStylesSecondary}
            onClick={handleLogout}
          >
            Log Out
          </Button>
          <Button
            className={sharedClasses.buttonStyles}
            onClick={handleAgree}
            isLoading={acceptLoading}
          >
            I Agree
          </Button>
        </Pane>
      </Modal.Body>
    </Modal>
  )
}

export default TermsAndConditions
