export const generateRandomString = (length = 32) => {
  const bytes = new Uint8Array(length)
  const result = []
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  // @ts-ignore // msCrypto only works with IE and thats why it throws an error here
  const crypto = window.crypto || window.msCrypto
  const random = crypto?.getRandomValues(bytes) || []
  for (const el of random){
      result.push(charset[el % charset.length])
  }

  return result.join('')
}
