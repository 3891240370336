// See https://auth0.com/blog/securing-gatsby-with-auth0/
import auth0 from 'auth0-js'
import { navigate } from 'gatsby'
import { generateRandomString } from './string'
import { HASURA_ALLOWED_ROLES_KEY, HASURA_CLAIMS_KEY } from './constants'

const isBrowser = typeof window !== 'undefined'
const AUTHO_STATE_KEY = `state`

// using the graphql URL here to conserve on environment variable, as they are the same value
const AUTH0_AUDIENCE = process.env.GATSBY_AUTH0_AUDIENCE

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_ISSUER_BASE_URL,
      clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: 'token id_token',
      scope: 'openid profile email',
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    })
  : {}

let user = {}
let isCheckingSession = false
let tabcatUserAuthToken

/**
 * Check if the user is authenticated
 * @returns {boolean}
 */
export const isAuthenticated = () => {
  if (!isBrowser) {
    return 
  }

  const isUserLoggedIn = localStorage.getItem('tabcat-isUserLoggedIn')
  const expiresAt = localStorage.getItem('tabcat-userAuthToken-expiresAt')

  if (isUserLoggedIn && expiresAt > new Date().getTime()) {
    return true
  }

  return
}

/**
 * Login the user
 */
export const login = () => {
  if (!isBrowser) {
    return
  }

  const state = generateRandomString()
  localStorage.setItem(AUTHO_STATE_KEY, state)
  auth.authorize({ state, audience: AUTH0_AUDIENCE })
}

/**
 * Process the hash from the URL and resolve with the authResult
 * @param hash
 * @returns {Promise<unknown>}
 */
async function processHash(hash) {
  // wraps auth.parseHash in a promise and resolves with the authResult, currently operates on callbacks
  return new Promise((resolve, reject) => {
    if(!hash || !localStorage.getItem(AUTHO_STATE_KEY)) {
      reject(`processHash error: hash or state is missing`)
    }
    auth.parseHash({ hash, state: localStorage.getItem(AUTHO_STATE_KEY) }, (err, authResult) => {
      if (err) {
        console.error('processHash error: ', err)
        reject(err)
      }
      resolve(authResult)
    })
  })
}

/**
 * Check the session and resolve with the authResult
 * @returns {Promise<unknown>}
 */
async function checkSession() {
  // wraps auth.checkSession in a promise and resolves with the authResult, currently operates on callbacks
  return new Promise((resolve, reject) => {
    if(!AUTH0_AUDIENCE) {
      reject(`checkSession error: AUTH0_AUDIENCE not set`)
    }
    auth.checkSession({ 
      audience: AUTH0_AUDIENCE, 
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK
    }, (err, authResult) => {
      if (err) {
        reject(err)
      }
      resolve(authResult)
    })
  })
}

/**
 * Logout the user
 */
export const logout = () => {
  if (!isBrowser) {
    return
  }

  localStorage.removeItem('tabcat-isUserLoggedIn')
  tabcatUserAuthToken = null
  localStorage.removeItem('tabcat-userAuthToken-expiresAt')

  auth.logout({
    returnTo: process.env.GATSBY_AUTH0_CALLBACK,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID
  })
}

/**
 * Set the session and resolve with the authResult
 * @param cb
 * @returns {(function(*, *): (*|undefined))|*}
 */
const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    console.error('SetSession error: ', err)
    if (err.code === 'login_required') {
      login()
    }

    cb()
    return err
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    user = authResult.idTokenPayload

    // This is global session, which still runs if you close the window and come back later (but don't logout),
    // that's why it is stored in local storage. authResult.expiresIn = 7200. That * 1000 = 120 minutes in total
    // The local session runs in the IdleWrapper component
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    localStorage.setItem('tabcat-isUserLoggedIn', true)
    tabcatUserAuthToken = authResult.idToken
    localStorage.setItem('tabcat-userAuthToken-expiresAt', expiresAt)

    if (isCheckingSession) {
      isCheckingSession = false
    } else {
      // logged in for the first time, direct to studies page
      navigate('/studies')
    }

    cb()
  }
}

/**
 * Handle the authentication
 * @returns {Promise<unknown>}
 */
export const handleAuthentication = async () => {
  if (!isBrowser) {
    return
  }
  let authResult
  try {
    authResult = await processHash(window.location.hash)
    setSession()(null, authResult)
    return authResult
  } catch (err) {
    console.error('handleAuthentication error: ', err)
    throw setSession()(err, null)
  }
}

/**
 * Get the profile of the user
 * @returns {{}}
 */
export const getProfile = () => {
  return user
}

/**
 * Silent authentication
 * @param callback
 * @returns {Promise<*>}
 */
export const silentAuth = async callback => {
  let authResult
  isCheckingSession = true
  
  // if the user is on the callback route, we do not need to check for a session, should be reasonably sure we have one
  if(window.location.pathname.includes('callback') && window.location.hash.length > 0 ) {
    try {
      authResult = await processHash(window.location.hash)
      setSession(callback)(null, authResult)
      return authResult
    } catch (err) {
      console.error('silentAuth processHash error: ', err)
      throw setSession()(err, null)
    }
  } else {
    // if the user is not on the callback route, we need to check for a session manually
    try {
      authResult = await checkSession()
      setSession(callback)(null, authResult)
      return authResult
    } catch (err) {
      console.error('silentAuth checkSession error: ', err)
      throw setSession()(err, null)
    }
  }
}

/**
 * Change password
 * @param email
 * @param callback
 */
export const changePassword = (email, callback) => {
  auth.changePassword(
    {
      connection: 'Username-Password-Authentication',
      email
    },
    callback
  )
}

/**
 * Get the token
 * @returns {string}
 */
export const getToken = () => {
  if (tabcatUserAuthToken) {
    return tabcatUserAuthToken
  } 
}

/** 
 * Get the user role
 * @returns {string}
 */
export const getUserRole = () => {
  const user = getProfile()
  if(!user || !user?.sub) {
    return 'user'
  }
  const claims = user[HASURA_CLAIMS_KEY]
  const roles = claims[HASURA_ALLOWED_ROLES_KEY]
  if(!roles ||!claims) {
    return 'user'
  }
  const rolePriority = ['System Admin', 'Realm Admin', 'Data Admin', 'Rater']
  return rolePriority.find(role => roles.includes(role)) ?? 'user'
}
