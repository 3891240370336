import gql from 'graphql-tag'

export const REMOVE_USER_FROM_REALM_MUTATION = gql`
  mutation removeUserFromRealm($realmId: Int!, $userId: String!) {
    delete_Realm_User(
      where: { realm_id: { _eq: $realmId }, user_id: { _eq: $userId } }
    ) {
      returning {
        User {
          familyName
          givenName
          email
          createdAt
          id
          name
          isDisplayBlocked
        }
        Realm {
          id
        }
      }
    }
  }
`
