import _ from 'lodash'

export const DELETE_REF = 'DELETE_REF'
export const ADD_REF = 'ADD_REF'

export const processElementManagement = (
  newElements = [],
  oldElements = [],
  addElement = () => {},
  removeElement = () => {}
) => {
  const promises = []
  for (const newEl of newElements) {
    if (!oldElements.some(ot => ot.id === newEl.id) && newEl.value) {
      promises.push(addElement(newEl.id))
    }
  }
  for (const oldElement of oldElements) {
    if (!newElements.filter(t => t.value).some(nt => nt.id === oldElement.id)) {
      promises.push(removeElement(oldElement.id))
    }
  }
  return promises
}

export const modifyAggregate = (elementsAggregate, type = ADD_REF) => {
  const count =
    type === ADD_REF
      ? elementsAggregate?.aggregate?.count + 1
      : elementsAggregate?.aggregate?.count - 1
  return {
    aggregate: {
      count
    }
  }
}

export const updateStudyTaskCache = (cache, task, study, type = ADD_REF) => {
  cache.modify({
    id: cache.identify(study),
    fields: {
      Study_Tasks(currentStudyTasks = [], { toReference, readField }) {
        if (type === ADD_REF) {
          return _.sortBy(
            [
              ...currentStudyTasks,
              {
                __typename: 'Study_Task',
                study_id: study.id,
                task_id: task.id,
                Task: toReference(task)
              }
            ],
            el => readField('name', el.Task)
          )
        } else {
          return currentStudyTasks.filter(
            r => r.Task?.__ref !== toReference(task)?.__ref
          )
        }
      },
      Study_Tasks_aggregate(currentStudyTasksAggreagate) {
        return modifyAggregate(currentStudyTasksAggreagate, type)
      }
    }
  })
}

export const updateStudyTaskBatteryCache = (
  cache,
  task,
  study,
  type = ADD_REF
) => {
  cache.modify({
    id: cache.identify(study),
    fields: {
      Study_TaskBatteries(
        currentStudyTaskBatteries = [],
        { toReference, readField }
      ) {
        if (type === ADD_REF) {
          return _.sortBy(
            [
              ...currentStudyTaskBatteries,
              {
                __typename: 'Study_TaskBattery',
                study_id: study.id,
                taskBattery_id: task.id,
                Task: toReference(task)
              }
            ],
            el => readField('name', el.Task)
          )
        } else {
          return currentStudyTaskBatteries.filter(
            r => r.TaskBattery?.__ref !== toReference(task)?.__ref
          )
        }
      },
      Study_TaskBatteries_aggregate(currentStudyTaskBatteriesAggreagate) {
        return modifyAggregate(currentStudyTaskBatteriesAggreagate, type)
      }
    }
  })
}

export const updateStudySiteCache = (cache, site, study, type = ADD_REF) => {
  function Study_Sites_aggregate(currentSiteStudiesAggreagate) {
    return modifyAggregate(currentSiteStudiesAggreagate, type)
  }
  cache.modify({
    id: cache.identify(study),
    fields: {
      Study_Sites(currentSiteStudies = [], { toReference, readField }) {
        if (type === ADD_REF) {
          return _.sortBy(
            [...currentSiteStudies, { Site: toReference(site) }],
            el => readField('name', el.Site)
          )
        } else {
          return currentSiteStudies.filter(
            r => r.Site?.__ref !== toReference(site)?.__ref
          )
        }
      },
      Study_Sites_aggregate
    }
  })
  cache.modify({
    id: cache.identify(site),
    fields: {
      Study_Sites(currentSiteStudies = [], { toReference, readField }) {
        if (type === ADD_REF) {
          return _.sortBy(
            [...currentSiteStudies, { Study: toReference(study) }],
            el => readField('name', el.Study)
          )
        } else {
          return currentSiteStudies.filter(
            r => r.Study?.__ref !== toReference(study)?.__ref
          )
        }
      },
      Study_Sites_aggregate
    }
  })
}

export const updateSiteUserCache = (cache, site, user, type = ADD_REF) => {
  function Site_Users_aggregate(currentSiteUsersAggreagate) {
    return modifyAggregate(currentSiteUsersAggreagate, type)
  }
  cache.modify({
    id: cache.identify(user),
    fields: {
      Site_Users(currentSiteUsers = [], { toReference, readField }) {
        if (type === ADD_REF) {
          return _.sortBy(
            [...currentSiteUsers, { Site: toReference(site) }],
            el => readField('name', el.Site)
          )
        } else {
          return currentSiteUsers.filter(
            r => r.Site?.__ref !== toReference(site)?.__ref
          )
        }
      },
      Site_Users_aggregate
    }
  })
  cache.modify({
    id: cache.identify(site),
    fields: {
      Site_Users(currentSiteUsers = [], { toReference, readField }) {
        if (type === ADD_REF) {
          return _.sortBy(
            [...currentSiteUsers, { User: toReference(user) }],
            el => readField('familyName', el.User)
          )
        } else {
          return currentSiteUsers.filter(
            r => r.User?.__ref !== toReference(user)?.__ref
          )
        }
      },
      Site_Users_aggregate
    }
  })
}

export const updateUserRealmCache = (cache, realm, user, type = ADD_REF) => {
  function Realm_Users_aggregate(currentRealmUsersAggreagate) {
    return modifyAggregate(currentRealmUsersAggreagate, type)
  }
  cache.modify({
    id: cache.identify(user),
    fields: {
      Realm_Users(currentUserRealms = [], { toReference }) {
        if (type === ADD_REF) {
          return [...currentUserRealms, { Realm: toReference(realm) }]
        } else {
          return currentUserRealms.filter(
            r => r.Realm?.__ref !== toReference(realm)?.__ref
          )
        }
      },
      Realm_Users_aggregate
    }
  })
  cache.modify({
    id: cache.identify(realm),
    fields: {
      Realm_Users(currentUserRealms = [], { toReference }) {
        if (type === ADD_REF) {
          return [...currentUserRealms, { User: toReference(user) }]
        } else {
          return currentUserRealms.filter(
            r => r.User?.__ref !== toReference(user)?.__ref
          )
        }
      },
      Realm_Users_aggregate
    }
  })
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      User(currentUserRealms = [], { storeFieldName, toReference, readField }) {
        if (storeFieldName.includes(`"realm_id":{"_eq":${realm?.id}}`)) {
          if (type === ADD_REF) {
            return _.sortBy(
              [...currentUserRealms, toReference(user)],
              currentUser => readField('familyName', currentUser)
            )
          } else {
            return currentUserRealms.filter(
              r => r.__ref !== toReference(user)?.__ref
            )
          }
        }
        return [...currentUserRealms]
      }
    }
  })
}

export const updateSiteRealmCache = (cache, realm) => {
  function Sites_aggregate(currentRealmSiteAggreagate) {
    return modifyAggregate(currentRealmSiteAggreagate, ADD_REF)
  }
  cache.modify({
    id: cache.identify(realm),
    fields: {
      Sites_aggregate
    }
  })
}

export const updateStudyRealmCache = (cache, realm) => {
  function Studies_aggregate(currentRealmStudiesAggreagate) {
    return modifyAggregate(currentRealmStudiesAggreagate, ADD_REF)
  }
  cache.modify({
    id: cache.identify(realm),
    fields: {
      Studies_aggregate
    }
  })
}
