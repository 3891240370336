import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { client } from '../../graphql-client'
import { silentAuth } from '../utils/auth'
import IdleWrapper from './IdleWrapper'
import Welcome from './Welcome'
import { Location } from '@reach/router'

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: false
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/callback') {
      silentAuth(this.handleCheckSession).catch(err =>
        this.setState({ error: true })
      )
    }
  }

  render() {
    return (
      <>
        {!this.state.loading ? (
          <React.Fragment>{this.props.children}</React.Fragment>
        ) : (
          <Welcome text={this.state.error ? 'Redirecting to login...' : ''} />
        )}
      </>
    )
  }
}

export default ({ element }) => (
  <ApolloProvider client={client}>
    <Location>
      {location => (
        // Checks for global session using local storage for 120 minutes
        <SessionCheck {...location}>
          {/* Checks for any action for 19 minutes, then shows a popup for 1 minute */}
          <IdleWrapper>{element}</IdleWrapper>
        </SessionCheck>
      )}
    </Location>
  </ApolloProvider>
)
