import React, { useEffect, useRef, useState } from 'react'
import { Button, Pane } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import _ from 'lodash'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  stepper: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block'
  },
  stepWrapper: {
    width: '100%',
    height: '490px',
    textAlign: 'center',
    opacity: 0
  },
  stepWrapperSmall: {
    width: '100%',
    height: '490px',
    textAlign: 'center',
    opacity: 0
  },
  stepWrapperVisited: {
    composes: '$stepWrapper',
    opacity: 1,
    transition: 'opacity .3s linear'
  },
  stepWrapperVisitedSmall: {
    composes: '$stepWrapperSmall',
    opacity: 1,
    transition: 'opacity .3s linear'
  },
  stepperSelector: {
    position: 'relative',
    width: '100%',
    height: '490px',
    top: '0'
  },
  step: {
    height: '420px',
    padding: '0 10px',
    overflowY: 'hidden'
  },
  stepSmall: {
    height: '340px',
    padding: '0 10px',
    overflowY: 'hidden'
  },
  buttons: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-around'
  },
  oneButtonWrapper: {
    composes: '$buttons',
    justifyContent: 'flex-end'
  },
  oneButtonWrapperCentered: {
    composes: '$buttons',
    justifyContent: 'center'
  },
  stepperProgress: {
    position: 'relative',
    top: '0px',
    width: '100%',
    padding: '20px 0',
    background: `${myTheme.colors.white}`,
    zIndex: '1'
  },
  stepperProgressWrapper: {
    width: '90%',
    position: 'relative',
    display: 'flex',
    margin: 'auto'
  },
  stepperProgressBar: {
    position: 'absolute',
    width: '100%',
    height: '1px',
    top: '10px',
    zIndex: '-1',
    background: `${myTheme.colors.grayTeal}`,
    transition: 'width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s'
  },
  stepTitle: {
    textAlign: 'center',
    fontSize: '0.7rem',
    alignItems: 'center',
    height: '30px',
    color: `${myTheme.colors.grey}`,
    backgroundColor: `${myTheme.colors.white}`,
    '& span': {
      marginLeft: '10px'
    }
  },
  stepTitleNumber: {
    fontSize: '.8rem',
    background: `${myTheme.colors.white}`,
    height: '20px',
    width: '20px',
    margin: 'auto',
    lineHeight: '1.5',
    border: `1px solid ${myTheme.colors.grey}`,
    borderRadius: '100%',
    display: 'inline-block',
    '& span': {
      marginLeft: '10px'
    }
  },
  stepTitleVisited: {
    composes: '$stepTitle',
    color: `${myTheme.colors.darkNavy}`,
    fontWeight: 'bold',
    fontSize: '.9rem',
    transition: 'font-size 1s cubic-bezier(0.23, 1, 0.32, 1) 0s'
  },
  stepTitleNumberVisited: {
    composes: '$stepTitleNumber',
    color: `${myTheme.colors.darkNavy}`,
    border: `1px solid ${myTheme.colors.stepperBorderTitle}`,
    background: `${myTheme.colors.stepperBackgroundTitle}`
  }
})

export const StepperProgress = ({ stepTitles, currentStep }) => {
  const sharedClasses = useSharedStyles()
  const hasOneStep = stepTitles.length === 1
  const progressPerStep = !hasOneStep ? 100 / (stepTitles.length - 1) : 0
  const progress =
    stepTitles.length >= currentStep ? (currentStep - 1) * progressPerStep : 100
  return (
    <Pane className={sharedClasses.stepperProgress}>
      <Pane
        className={sharedClasses.stepperProgressWrapper}
        justifyContent={hasOneStep ? 'center' : 'space-between'}
      >
        <Pane
          className={sharedClasses.stepperProgressBar}
          style={{ width: `${progress}%` }}
        />
        {stepTitles.map((title, i) => {
          const isVisited = currentStep === i + 1
          return (
            <Pane
              className={
                isVisited
                  ? sharedClasses.stepTitleVisited
                  : sharedClasses.stepTitle
              }
              key={i}
            >
              <Pane
                className={
                  isVisited
                    ? sharedClasses.stepTitleNumberVisited
                    : sharedClasses.stepTitleNumber
                }
              >
                {i + 1}
              </Pane>
              <span>{title}</span>
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}

const Stepper = ({
  children = [],
  steps,
  onConfirm,
  onBack,
  withoutProgress = false,
  size = 'normal'
}) => {
  const sharedClasses = useSharedStyles()
  const [currentStep, setCurrentStep] = useState(1)
  const stepperSelector = useRef(null)
  const length = _.isArray(children) ? children.filter(c => c).length : 1
  const currentChildren = _.isArray(children)
    ? children.filter(c => c)
    : children
  const isLastStep = currentStep === length
  const isFirstStep = currentStep === 1

  useEffect(() => {
    const moveStepper = () => {
      if (stepperSelector.current) {
        const stepper = stepperSelector.current
        const stepHeight = stepper.offsetHeight
        stepper.style.transform = `translateY(-${
          stepHeight * (currentStep - 1)
        }px)`
      }
    }

    moveStepper()
  }, [currentStep])

  const goNextStep = () => {
    const nextStep = currentStep + 1
    if (nextStep <= length) {
      setCurrentStep(nextStep)
    } else {
      onConfirm()
    }
  }

  const goPreviousStep = () => {
    const previousStep = currentStep - 1
    if (previousStep >= 1) {
      setCurrentStep(previousStep)
    } else {
      onBack()
    }
  }

  return (
    <Pane className={sharedClasses.stepper}>
      {!withoutProgress && (
        <StepperProgress stepTitles={steps} currentStep={currentStep} />
      )}
      <Pane className={sharedClasses.stepperSelector} ref={stepperSelector}>
        {React.Children.map(currentChildren, (step, index) => {
          const isVisited = currentStep === index + 1
          return (
            <Pane
              className={
                size === 'small'
                  ? isVisited
                    ? sharedClasses.stepWrapperVisitedSmall
                    : sharedClasses.stepWrapperSmall
                  : isVisited
                  ? sharedClasses.stepWrapperVisited
                  : sharedClasses.stepWrapper
              }
              key={index}
            >
              {React.cloneElement(step, {
                goNextStep,
                goPreviousStep,
                isVisited,
                currentStep,
                isFirstStep,
                isLastStep
              })}
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}

const Step = ({
  goNextStep,
  goPreviousStep,
  isVisited,
  isFirstStep,
  confirmButtonLabel,
  isLastStep,
  disableBack = isFirstStep,
  disableNext,
  nextLoading,
  AdditionalOptions,
  children,
  size = 'normal'
}) => {
  const sharedClasses = useSharedStyles()

  if (!isVisited) {
    return null
  }

  return (
    <>
      <Pane
        className={
          size === 'small' ? sharedClasses.stepSmall : sharedClasses.step
        }
      >
        {children}
      </Pane>
      <Pane
        className={
          confirmButtonLabel === 'Close'
            ? sharedClasses.oneButtonWrapperCentered
            : disableBack && !AdditionalOptions
              ? sharedClasses.oneButtonWrapper
              : sharedClasses.buttons
        }
      >
        {!disableBack && (
          <Button
            className={sharedClasses.buttonStyles}
            onClick={goPreviousStep}
          >
            Back
          </Button>
        )}
        {AdditionalOptions}
        <Button
          className={sharedClasses.buttonStyles}
          disabled={disableNext}
          isLoading={nextLoading}
          onClick={goNextStep}
        >
          {confirmButtonLabel
            ? confirmButtonLabel
            : isLastStep
            ? 'Save'
            : 'Next'}
        </Button>
      </Pane>
    </>
  )
}
Stepper.Step = Step
export default Stepper
