import { Dialog, Pane, Button } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../../styles/sharedComponentStyles'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  buttons: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '93%'
  },
  textWrapper: {
    margin: '30px'
  }
})

const ChangePasswordDialog = ({ handleCloseModal, isShown, handleConfirm }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Dialog
      isShown={isShown}
      title="Change Password"
      onCloseComplete={handleCloseModal}
      hasCancel={false}
      hasFooter={false}
      preventBodyScrolling
      confirmLabel="Confirm"
    >
      <Pane className={sharedClasses.textWrapper}>
        Are you sure you want to change your password? You will be logged out
        and you will receive an email from tabcat@ucsf.edu containing a link to
        change your password.
      </Pane>
      <Pane className={sharedClasses.buttons}>
        <Button
          className={sharedClasses.buttonStylesSecondary}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button className={sharedClasses.buttonStyles} onClick={handleConfirm}>
          Confirm
        </Button>
      </Pane>
    </Dialog>
  )
}

export default ChangePasswordDialog
