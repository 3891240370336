import StatusRenderer from '../components/StatusRenderer'
import { getHighestRole } from '../components/UserDetails/userUtils'
import myTheme from '../styles/theme'

const getCellStyle = ({ data }) =>
  data?.isDisplayBlocked
    ? { color: myTheme.colors.grayTeal }
    : { color: myTheme.colors.darkNavy }

export const getStatusValue = ({ data }) =>
  data?.isDisplayBlocked ? 'Archived' : 'Active'

export const gridUserOptions = {
  frameworkComponents: {
    statusRenderer: StatusRenderer
  },
  columnDefs: [
    {
      headerName: 'Name',
      colId: 'name',
      valueGetter: ({ data }) => {
        let result = `${data?.familyName || ''}`
        if (data?.givenName) {
          result = `${result}, ${data.givenName}`
        }
        return result
      },
      cellStyle: getCellStyle
    },
    {
      headerName: 'Email',
      field: 'email',
      cellStyle: getCellStyle
    },
    {
      headerName: 'Role',
      valueGetter: ({ data }) => getHighestRole(data),
      cellStyle: getCellStyle
    },
    {
      headerName: 'Status',
      colId: 'site',
      cellRenderer: 'statusRenderer',
      valueGetter: getStatusValue,
      cellStyle: getCellStyle,
      headerTooltip:
        'Archived users are uneditable and are disabled from logging in to TabCAT. User connections, to TabCAT data remain intact.',
      headerComponentParams: {
        menuIcon: 'fa-bars',
        template: `<div class="ag-cell-label-container" role="presentation">  
                          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" ></span>  
                          <div ref="eLabel" class="ag-header-cell-label" role="presentation">    
                              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>    
                              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>    
                              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>    
                              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>    
                              <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: pre-line; word-break: break-all;
                              word-spacing: -1px; margin-right: 5px;"></span><span class="ag-icon ag-icon-eye"></span>
                              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>  
                          </div>
                      </div>`
      }
    }
  ]
}
