import myTheme, { breakpoints } from './theme'

export const generalStyles = {
  section: {
    margin: '30px auto 0',
    maxWidth: '1200px',
    background: `${myTheme.colors.lightTeal}`
  }
}

export const formStyles = {
  row: {
    margin: '0 0 15px',
    borderColor: '#ffffff',
    height: 'auto',
    alignItems: 'flex-start'
  },
  cell: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  label: {
    display: 'inline-grid',
    fontWeight: 100,
    marginBottom: '10px',
    color: `${myTheme.colors.grey}`
  },
  fieldsLabel: {
    composes: '$label',
    color: `${myTheme.colors.labelDark}`,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px'
  },
  labelWithTooltip: {
    composes: '$label',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: '10px',
      bottom: '10px'
    }
  },
  fullWidthLabel: {
    composes: '$label',
    width: '100%'
  },
  createLabel: {
    composes: '$label',
    fontWeight: 400,
    background: `${myTheme.colors.lightTeal}`,
    color: `${myTheme.colors.blueNavy}`,
    padding: '15px 0 0',
    '& p': {
      background: '#ffffff',
      padding: '15px 0 0 30px',
      color: `${myTheme.colors.grey}`
    }
  },
  input: {
    border: 0,
    background: `${myTheme.colors.fieldBackground}`,
    padding: '10px',
    boxShadow: 'none',
    borderRadius: '3px',
    marginTop: '5px',
    width: '93%',
    fontFamily: 'Roboto',
    '&:disabled': {
      boxShadow: 'none'
    }
  },
  roundedInput: {
    composes: '$input',
    borderRadius: '10px',
    '& + div': {
      marginTop: '0px'
    }
  },
  datepickerInput: {
    '& .DayPickerInput': {
      width: '93%',
      '& input': {
        width: '95%',
        padding: '12px',
        borderRadius: '10px',
        border: 'none',
        background: `${myTheme.colors.fieldBackground}`,
        '&:focus': {
          outline: 'none',
          boxShadow:
            'inset 0 0 2px rgb(67 90 111 / 14%), inset 0 0 0 1px #579ad9, 0 0 0 3px rgb(16 112 202 / 14%)'
        }
      }
    }
  },
  datepickerBorderError: {
    '& input': {
      boxShadow: `inset 0 0 0 1px ${myTheme.colors.errorBorder}, inset 0 1px 2px rgb(67 90 111 / 14%)`
    }
  },
  select: {
    margin: '10px 0',
    width: '170px',
    padding: '5px 35px 5px 5px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '3px',
    height: '34px',
    appearance: 'none',
    outline: 'none !important',
    background: `url(/down-arrow.png) 96% / 15% no-repeat ${myTheme.colors.fieldBackground}`
  },
  newSelectField: {
    margin: '5px 0',
    width: '93%',
    height: '40px',
    background: `${myTheme.colors.fieldBackground}`,
    borderRadius: '10px',
    '& select': {
      height: 'inherit',
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    },
    '& select:active': {
      background: 'transparent !important',
      border: 'none',
      boxShadow: 'none !important'
    },
    '& select:focus': {
      boxShadow: 'none !important'
    },
    '& select:hover': {
      background: 'transparent !important'
    },
    '& + div': {
      marginTop: '0px'
    }
  },
  textarea: {
    composes: '$input'
  },
  textareaFull: {
    composes: '$textarea',
    width: '95%'
  },
  submitButton: {
    composes: '$buttonStyles',
    display: 'none',
    borderRadius: '3px',
    padding: '0 30px',
    margin: 0,
    border: 'none',
    outline: 'none'
  },
  submitButtonReal: {
    visibility: 'hidden',
    left: '10000px',
    position: 'absolute'
  },
  tablist: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderBottom: '1px solid #f7f7f7',
    paddingBottom: '15px',
    marginBottom: '30px'
  },
  tablistModTwo: {
    marginBottom: 0
  },
  tablistTab: {
    marginLeft: 0,
    marginRight: '2px',
    borderRadius: '3px 3px 0 0',
    fontSize: '20px',
    padding: '25px 35px',
    color: '#e6e6e6',
    background: `${myTheme.colors.blueNavy}`,
    '&:hover': {
      background: `${myTheme.colors.blueNavy}`,
      color: '#e6e6e6',
      border: `1px solid #ffffff`
    },
    '&[aria-selected="true"]': {
      color: '#ffffff',
      background: `${myTheme.colors.darkNavy}`,
      boxShadow: 'none'
    },
    '&:focus': {
      outline: 'none',
      color: '#e6e6e6',
      border: `1px solid #ffffff`
    }
  },
  selectMultiple: {
    border: 0,
    background: `${myTheme.colors.lightTeal}`,
    padding: '10px',
    width: '100%',
    '&:focus-visible': {
      outline: 'none',
      border: 0
    },
    '& option': {
      padding: '10px 0'
    }
  },
  darkerCheckbox: {
    marginBottom: '0px',
    marginTop: '22px',
    '& span': {
      backgroundColor: `${myTheme.colors.fieldBackground}`,
      width: '88%',
      padding: '10px',
      borderRadius: '10px',
      marginTop: '-10px'
    }
  },
  darkerRadioOption: {
    marginBottom: '10px',
    marginTop: '25px',
    '& span': {
      backgroundColor: `${myTheme.colors.fieldBackground}`,
      width: '88%',
      padding: '10px',
      borderRadius: '10px'
      //marginTop: '-10px'
    },
    '& div:first-of-type': {
      marginTop: '12px'
    }
  },
  nestedList: {
    listStyle: 'none',
    padding: '15px'
  },
  fieldError: {
    color: `${myTheme.colors.errorText}`,
    lineHeight: '18px',
    fontSize: '12px'
  }
}

export const sidesheetStyles = {
  '@keyframes moveLeftToRight': {
    from: { transform: 'translate(5000px, 0)' },
    to: { transform: 'translate(0, 0)' }
  },
  sideSheet: {
    position: 'absolute',
    height: 'auto',
    width: '100vw',
    zIndex: 10,
    top: '120px',
    left: 0,
    transform: 'translate(5000px, 0)',
    background: myTheme.colors.lightTeal,
    '&.show': {
      animation: '$moveLeftToRight .5s ease-in-out 0s forwards'
    }
  },
  siteUlLists: {
    listStyleType: 'none',
    padding: 0,
    '& li': {
      fontSize: '15px',
      cursor: 'pointer',
      padding: '10px 20px',
      marginBottom: '5px',
      borderRadius: '3px',
      background: `${myTheme.colors.fieldBackground}`,
      '&.active': {
        background: myTheme.colors.mediumTeal
      }
    }
  },
  panelInnerDiv: {
    padding: '30px',
    width: 'auto',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexDirection: 'column'
  },
  panelUsersDiv: {
    composes: '$panelInnerDiv',
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      display: 'none',
      '&.show': {
        display: 'block',
        '& li': {
          background: `${myTheme.colors.fieldBackground}`,
          padding: '10px 20px',
          marginBottom: '5px',
          borderRadius: '3px'
        }
      }
    }
  },
  panelPane: {
    overflowY: 'auto',
    flex: 1,
    borderRadius: '6px'
  },
  panelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  taskList: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 20px',
      marginBottom: '5px',
      borderRadius: '3px',
      background: `${myTheme.colors.fieldBackground}`
    }
  }
}

export const customListStyles = {
  listItem: {
    position: 'relative',
    margin: '10px 0 0',
    padding: '10px',
    display: 'flex',
    backgroundColor: `${myTheme.colors.lightTeal}`,
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    },
    '&.selected div': {
      backgroundColor: '#000000'
    },
    '& span': {}
  },
  controlIndicator: {
    position: 'absolute',
    top: '12px',
    left: '-23px',
    height: '15px',
    width: '15px',
    backgroundColor: '#e5e5e5',
    border: '1px solid #000000',
    borderRadius: '50%',
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'block',
      left: '5px',
      top: '5px',
      height: '20px',
      width: '20px',
      borderRadius: '50%'
    }
  },
  buttonStyles: {
    fontWeight: 'bold',
    color: `${myTheme.colors.white}`,
    fontSize: '15px',
    padding: '20px 30px',
    background: `${myTheme.colors.darkTeal}`,
    textDecoration: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    border: 0,
    whiteSpace: 'nowrap',
    lineHeight: 0,
    margin: '5px',
    '&:hover, &:focus, &:active': {
      //backgroundImage: 'none !important',
      //boxShadow: 'none !important',
      background: `${myTheme.colors.darkTeal} !important`
    },
    '&:first-of-type': {
      marginRight: '30px !important'
    },
    '& a': {
      color: `${myTheme.colors.white}`,
      textDecoration: 'none'
    },
    [`@media (max-width:${breakpoints.sm})`]: {
      fontSize: '12px'
    }
  },
  buttonStylesSecondary: {
    composes: '$buttonStyles',
    background: `${myTheme.colors.grayTeal}`,
    color: `${myTheme.colors.white}`,
    '&:hover, &:focus, &:active': {
      background: `${myTheme.colors.grayTeal} !important`
    }
  },
  buttonStylesTerciary: {
    composes: '$buttonStyles',
    background: `${myTheme.colors.white}`,
    color: `${myTheme.colors.darkTeal}`,
    border: `1px dashed ${myTheme.colors.darkTeal}`,
    boxShadow: 'none',
    '&:hover': {
      background: `${myTheme.colors.white}`
    }
  },
  buttonStylesDanger: {
    composes: '$buttonStyles',
    background: `${myTheme.colors.errorText}`,
    color: `${myTheme.colors.white}`,
    '&:hover, &:focus, &:active': {
      background: `${myTheme.colors.errorText} !important`
    }
  },
  panelButtonStyles: {
    composes: '$buttonStyles',
    marginRight: '0 !important'
  },
  submitButton: {
    composes: '$buttonStyles',
    borderRadius: '3px',
    margin: 0,
    border: 'none',
    outline: 'none',
    height: '32px',
    lineHeight: 0,
    cursor: 'pointer'
  },
  submitButtonReal: {
    visibility: 'hidden',
    left: '10000px',
    position: 'absolute'
  },
  iconButton: {
    background: `${myTheme.colors.iconBackground}`,
    border: `2px solid ${myTheme.colors.iconText}`,
    borderRadius: '3px',
    fontSize: '1.1rem',
    padding: '8px',
    color: `${myTheme.colors.iconText}`
  },
  buttonStylesDownload: {
    fontWeight: 'bold',
    color: `${myTheme.colors.white}`,
    fontSize: '15px',
    padding: '12px 18px',
    background: `${myTheme.colors.darkTeal}`,
    textDecoration: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    border: 0,
    whiteSpace: 'nowrap',
    lineHeight: 0,
    margin: '5px',
    '&:hover, &:focus, &:active': {
      //backgroundImage: 'none !important',
      //boxShadow: 'none !important',
      background: `${myTheme.colors.darkTeal} !important`
    },
    '& a': {
      color: `${myTheme.colors.white}`,
      textDecoration: 'none'
    },
    [`@media (max-width:${breakpoints.sm})`]: {
      fontSize: '12px'
    }
  }
}

export const tableSectionStyles = {
  tableContainer: {
    margin: '40px 20px'
  },
  table: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto 30px',
    background: '#ffffff',
    '& button': {
      marginRight: '0px !important'
    }
  },
  tableHeader: {
    background: `${myTheme.colors.blueNavy}`,
    padding: '20px',
    height: 'auto',
    display: 'flex',
    borderRadius: '5px 5px 0 0',
    '& span': {
      fontSize: '20px',
      color: '#ffffff',
      fontWeight: '400'
    },
    '& button': {
      fontSize: '15px',
      color: '#ffffff'
    }
  },
  tableHeaderModTwo: {
    composes: '$tableHeader',
    padding: '12px 0'
  },
  tableHeaderCell: {
    padding: 0,
    '& button': {
      height: 'auto'
    }
  },
  tableHeaderSideSheet: {
    '& span': {
      fontSize: '1.5em',
      color: `${myTheme.colors.blueNavy}`,
      fontWeight: 400
    }
  },
  tableBody: {
    padding: '20px 20px 30px'
  },
  tableRow: {
    background: `${myTheme.colors.lightTeal}`,
    borderBottom: 'none',
    marginBottom: '5px',
    '& span': {
      color: `${myTheme.colors.grayTeal}`
    },
    '[data-isselectable="true"]&:hover': {
      background: `${myTheme.colors.lightBlue}`
    },
    '[data-isselectable="true"]& span': {
      color: `${myTheme.colors.blueNavy}`
    }
  },
  tableRowCognitiveScores: {
    background: `${myTheme.colors.white}`,
    borderBottom: 'none',
    marginBottom: '5px',
    '& span': {
      color: `${myTheme.colors.grayTeal}`
    },
    '[data-isselectable="true"]&:hover': {
      background: `${myTheme.colors.lightBlue}`
    },
    '[data-isselectable="true"]& span': {
      color: `${myTheme.colors.blueNavy}`
    }
  },
  tableCell: {
    padding: '30px',
    margin: '20px',
    textAlign: 'center',
    borderRadius: '6px',
    backgroundColor: myTheme.colors.lightTeal,
    '& span': {
      fontSize: '15px'
    }
  },
  tableCellCenter: {
    textAlign: 'center'
  },
  tableFirstHeaderCell: {
    paddingLeft: '40px'
  },
  tableFirstRowCell: {
    paddingLeft: '20px'
  }
}

export const navStyles = {
  nav: {
    background: '#ffffff',
    '& h1 span': {
      borderLeft: '1px solid #000000',
      margin: '0 15px',
      padding: '0 15px',
      fontWeight: 100
    },
    '& h1 svg': {
      marginRight: '15px',
      cursor: 'pointer',
      color: `${myTheme.colors.darkTeal}`
    }
  },
  secondaryNav: {
    position: 'relative',
    display: 'flex',
    padding: '15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '1300px',
    height: '100px',
    zIndex: 10,
    [`@media (max-width:${breakpoints.md})`]: {
      height: '150px'
    }
  },
  autoComplete: {
    width: 'auto',
    '& input': {
      background: `${myTheme.colors.lightTeal}`,
      fontSize: '15px',
      padding: '20px',
      border: 0,
      [`@media (max-width:${breakpoints.sm})`]: {
        width: '100% !important'
      }
    }
  },
  customAccordion: {
    '& .accordion': {
      marginTop: '20px',
      '& .accordion__heading': {
        overflow: 'hidden'
      },
      '& .accordion__button': {
        backgroundColor: myTheme.colors.blueNavy,
        color: myTheme.colors.white
      }
    }
  }
}

export const modalStyles = {
  '@keyframes moveLeftToRight': {
    from: { transform: 'translate(0, 0)' },
    to: { transform: 'translate(-20%, 0)' }
  },
  '@keyframes moveLeftToRightTwo': {
    from: { transform: 'translate(-20%, 0)' },
    to: { transform: 'translate(-40%, 0)' }
  },
  '@keyframes moveLeftToRightThree': {
    from: { transform: 'translate(-40%, 0)' },
    to: { transform: 'translate(-60%, 0)' }
  },
  '@keyframes moveLeftToRightFour': {
    from: { transform: 'translate(-60%, 0)' },
    to: { transform: 'translate(-80%, 0)' }
  },
  '@keyframes fadeUp': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  modalSection: {
    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'space-between'
  },
  formSection: {
    // height: '400px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  paneSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    // height: '100%',
    width: '500%',
    '&.paneOne': {
      animation: '$moveLeftToRight 0.6s ease-in-out 0s forwards'
    },
    '&.paneTwo': {
      animation: '$moveLeftToRightTwo 0.6s ease-in-out 0s forwards'
    },
    '&.paneThree': {
      animation: '$moveLeftToRightThree 0.6s ease-in-out 0s forwards'
    },
    '&.paneFour': {
      animation: '$moveLeftToRightFour 0.6s ease-in-out 0s forwards'
    },
    '& + div': {
      opacity: 1
    }
  },
  paneDivs: {
    height: '100%',
    width: '20%',
    '&.show': {
      animation: '$fadeUp 0.3s ease-in-out 0.3s forwards'
    }
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 16px'
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    '& h4': {
      flex: 1,
      textAlign: 'center'
    },
    '& div': {
      cursor: 'pointer'
    }
  }
}

export const markdownStyles = {
  markdown: {
    '& h1': {
      fontSize: '1.9em'
    },
    '& table': {
      tableLayout: 'fixed',
      width: '80%',
      margin: '1rem auto',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      border: `1px solid ${myTheme.colors.markdownBorder}`,
      '& thead, & tbody': {
        background: myTheme.colors.white,
        border: '0',
        fontSize: '100%',
        margin: '0',
        padding: '0',
        verticalAlign: 'initial',
        '& th': {
          background: myTheme.colors.markdownTh,
          padding: '5px',
          borderLeftWidth: '0px',
          borderTopWidth: '0px'
        }
      },
      '& td': {
        border: `1px solid ${myTheme.colors.markdownBorder}`,
        padding: '5px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
      }
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '1rem',
      lineHeight: '1.5',
      color: myTheme.colors.markdownParagraph,
      overflowWrap: 'break-word'
    },
    '& img': {
      margin: '1rem auto',
      display: 'block',
      maxWidth: '80%',
      maxHeight: '250px'
    },
    '& h3': {
      fontSize: '1.6em'
    }
  }
}
