import { toaster } from 'evergreen-ui'
import { useEffect, useMemo } from 'react'
import { useCan } from '../components/Can'
import { GET_ALL_REALMS_QUERY } from '../queries/getAllRealms'
import { SYSTEM_ADMIN, ALL_REALMS_ID } from '../utils/constants'
import { getRealmIdFromURL } from '../utils/filters'
import { useGlobalStore } from './useGlobalStore'
import { useLocation } from '@reach/router'
import { useLazyQuery } from '@apollo/client'

const GET_ALL_REALMS_ERROR_MESSAGE =
  'Sorry, something went wrong getting all realms data, please try again later.'

export function useSetRealmData() {
  const {
    userData,
    realmData,
    //currentRealm,
    realmId,
    setRealmData,
    //setCurrentRealm,
    setRealmId
  } = useGlobalStore()
  const location = useLocation()

  const [
    getAllRealms,
    { loading: allRealmsLoading, data: allRealmsData }
  ] = useLazyQuery(GET_ALL_REALMS_QUERY, {
    onError: _ => toaster.danger(GET_ALL_REALMS_ERROR_MESSAGE)
  })
  const { verifyRoles } = useCan()

  const isSystemAdmin = verifyRoles(SYSTEM_ADMIN)

  useEffect(() => {
    /** call all the realms query if the user is admin */
    if (isSystemAdmin) {
      getAllRealms()
    }
  }, [isSystemAdmin, getAllRealms])

  const assignedRealms = useMemo(
    () =>
      allRealmsData?.Realm || userData?.Realm_Users?.map(realm => realm.Realm),
    [userData, allRealmsData]
  )
  const realmsLoading = allRealmsLoading

  useEffect(() => {
    /** set the realms options */
    const rawData =
      assignedRealms?.filter(item => item.isBlocked === false) || []
    setRealmData(rawData)
  }, [setRealmData, assignedRealms])

  useEffect(() => {
    /** set the current realm */
    if (realmData?.length > 0) {
      const defaultRealmId = realmData?.[0]?.id
      const routeRealm = getRealmIdFromURL(location)

      const isInAllRealms =
        location?.pathname?.includes('/realms') || realmId === ALL_REALMS_ID

      if (isInAllRealms) {
        setRealmId(Number(ALL_REALMS_ID))
      } else if (Number(routeRealm)) {
        setRealmId(Number(routeRealm))
      } else if (realmId && realmData.find(r => r.id === realmId)) {
        setRealmId(Number(realmId))
      } else {
        setRealmId(Number(defaultRealmId))
      }
    }
  }, [realmData, location, realmId, setRealmId])

  /*   useEffect(() => {
    // set realm id for current realm
    setRealmId(realmData?.find(r => r.id === realmId)?.id)
  }, [realmId, realmData]) */

  return { realmsData: assignedRealms, realmsLoading }
}
