import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { GET_TERMS_AND_CONDITIONS_QUERY } from '../queries/getTermsAndConditions'
import { useGlobalStore } from './useGlobalStore'

function isTermsAndConditionsOutOfDate(termsAndConditionsDoc, userData) {
  return (
    (typeof userData?.didAcceptTermsAndConditions === 'boolean' &&
      !userData?.didAcceptTermsAndConditions) ||
    Number(termsAndConditionsDoc?.sys?.publishedVersion) >
      Number(userData?.lastAcceptedTermsAndConditionsVersion)
  )
}

const GET_TERMS_AND_CONDITIONS_ERROR_MESSAGE =
  'Sorry, something went wrong getting the terms and conditions data, please try again later.'

export function useGetContentfulTermsAndConditions() {
  const [termsAndConditionsDoc, setTermsAndConditionsDoc] = useState()
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const { userData } = useGlobalStore()

  const { loading: termsAndConditionsLoading } = useQuery(
    GET_TERMS_AND_CONDITIONS_QUERY,
    {
      variables: {
        id:
          process.env.GATSBY_TERMS_AND_CONDITIONS_DOCUMENT_ID ||
          '2n9fLHRx4muFWKNGnOnwF9',
        preview: false
      },
      onCompleted: result => {
        if (result.document) {
          setTermsAndConditionsDoc(result.document)
        }
      },
      onError: _ => toaster.danger(GET_TERMS_AND_CONDITIONS_ERROR_MESSAGE)
    }
  )

  useEffect(() => {
    /** manage terms and conditions */
    if (isTermsAndConditionsOutOfDate(termsAndConditionsDoc, userData)) {
      setShowTermsAndConditions(true)
    } else {
      setShowTermsAndConditions(false)
    }
  }, [userData, termsAndConditionsDoc])

  return {
    termsAndConditionsLoading,
    showTermsAndConditions,
    setShowTermsAndConditions,
    termsAndConditionsDoc
  }
}
