import React, { useRef } from 'react'
import { Beforeunload } from 'react-beforeunload'
import { Heading, Pane, Spinner } from 'evergreen-ui'
import Header from './Header'
import { useGlobalStore } from '../hooks/useGlobalStore'
import TermsAndConditions from './TermsAndConditions'
import { useSetRealmData } from '../hooks/useSetRealmData'
import { useSetGlobalUserData } from '../hooks/useSetGlobalUserData'
import { useGetContentfulTermsAndConditions } from '../hooks/useGetContentfulTermsAndConditions'

const RealmWrapperLayout = ({ children }) => {
  const mainContainerRef = useRef(null)
  const { 
    //currentRealm,
    realmId,
    realmData, 
    showPrompt } = useGlobalStore()
  const { realmsData, realmsLoading } = useSetRealmData()
  const { userDataLoading } = useSetGlobalUserData()
  const {
    termsAndConditionsLoading,
    showTermsAndConditions,
    setShowTermsAndConditions,
    termsAndConditionsDoc
  } = useGetContentfulTermsAndConditions()

  return (
    <>
      {showPrompt && (
        <Beforeunload onBeforeunload={event => event.preventDefault()} />
      )}

      <Header mainContainerRef={mainContainerRef} />
      {!realmsLoading && realmsData?.length <= 0 && (
        <Heading size={800} is="h1">
          You don't have realms assigned. Please, contact your admin
        </Heading>
      )}
      {!realmsLoading && realmData.length > 0 && realmId && (
        <Pane ref={mainContainerRef} is="main">
          {children}
        </Pane>
      )}
      {(realmsLoading || userDataLoading) && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <h2>Loading </h2> <Spinner size={40} margin={15} />
        </Pane>
      )}
      {showTermsAndConditions && (
        <TermsAndConditions
          showDialog={showTermsAndConditions}
          setShowDialog={setShowTermsAndConditions}
          termsAndConditionsDoc={termsAndConditionsDoc}
          loading={termsAndConditionsLoading}
        />
      )}
    </>
  )
}

export default function GlobalLayout({ element, props }) {
  return <RealmWrapperLayout {...props}>{element}</RealmWrapperLayout>
}
