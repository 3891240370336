import React from 'react'
import { InfoSignIcon, Pane, Position, Tooltip } from 'evergreen-ui'
import { getStatusValue } from '../gridConfig/usersGridConfig'

const StatusRenderer = params => (
  <Pane display="flex" alignItems="center" justifyContent="flex-start">
    <span style={{ flex: '.5' }}>{getStatusValue(params)}</span>
    {params.data.isDisplayBlocked && (
      <Tooltip
        position={Position.RIGHT}
        content="Archived users are uneditable and are disabled from logging in to TabCAT. User connections to TabCAT data remain intact."
      >
        <InfoSignIcon />
      </Tooltip>
    )}
  </Pane>
)

export default StatusRenderer
