import { Button, Heading, Pane } from 'evergreen-ui'
import _ from 'lodash'
import React from 'react'
import { createUseStyles } from 'react-jss'
import {
  customListStyles,
  tableSectionStyles
} from '../../styles/sharedComponentStyles'
import myTheme from '../../styles/theme'
import { useLocation } from '@reach/router'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  ...tableSectionStyles,
  wrapper: {
    display: 'grid',
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: '#ffffff',
    minHeight: '300px',
    borderRadius: '6px',
    overflowY: 'auto',
    marginBottom: '30px'
  },
  sitesRow: {
    composes: '$tableRow',
    fontSize: '.9rem',
    '& span': {
      color: '#132246'
    }
  },
  sitesPaneBody: {
    composes: '$sitesRow',
    textAlign: 'left',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '40% repeat(2, 15%) 30%',
    '&:hover': {
      background: `${myTheme.colors.lightBlue}`
    }
  }
})

const UserStudiesSites = ({
  user,
  handleManageAccess,
  isBlocked,
  canEdit,
  imRealmAdmin
}) => {
  const sharedClasses = useSharedStyles()
  const location = useLocation()
  const userStudies = user?.studies || []
  const studiesSorted = _.orderBy(
    Object.entries(userStudies),
    [([key]) => key],
    ['asc']
  )

  return (
    <Pane className={sharedClasses.wrapper}>
      <Pane padding={30} display="flex" justifyContent="space-between">
        <Heading size={800}>User Access</Heading>
        {!isBlocked && canEdit && !imRealmAdmin && (
          <Button
            className={sharedClasses.buttonStyles}
            onClick={handleManageAccess}
          >
            Manage Access
          </Button>
        )}
        {imRealmAdmin && (
          <Heading size={200}>
            Realm Admins are automatically assigned to all sites and studies in
            the realm.
          </Heading>
        )}
      </Pane>
      <Pane paddingX={30} paddingY={10}>
        {studiesSorted?.map(([studyName, value]) => {
          const sitesSorted = _.orderBy(
            value,
            [item => item.site.name.toLowerCase()],
            ['asc']
          )
          return (
            <Pane className={sharedClasses.table} key={studyName}>
              <h3>
                {location
                  ? location.pathname
                    ? location.pathname.includes('-1')
                      ? `${value[0].realmName}, ${studyName}`
                      : studyName
                    : `${value[0].realmName}, ${studyName}`
                  : `${value[0].realmName}, ${studyName}`}
              </h3>
              {sitesSorted.map(item => (
                <Pane
                  className={sharedClasses.sitesPaneBody}
                  data-testid="site-studies"
                  key={item.site.name}
                >
                  <span>{item.site.name}</span>
                </Pane>
              ))}
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}

export default UserStudiesSites
