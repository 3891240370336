import React, { useEffect, useState } from 'react'
import {
  Button,
  Heading,
  InfoSignIcon,
  Pane,
  Position,
  toaster
} from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../../styles/sharedComponentStyles'
import myTheme, { breakpoints } from '../../styles/theme'
import InputTextField from '../forms/InputTextField'
import InputWithTooltip from '../forms/InputWithTooltip'
import TextAreaField from '../forms/TextAreaField'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { userValidationSchema } from './userFormSchema'
import { ACTIVATE_USER, SYSTEM_ADMIN } from '../../utils/constants'
import { changePassword, logout } from '../../utils/auth'
import {
  getHighestRole,
  UPDATE_STATUS_PERMISSIONS_ERROR,
  UPDATE_USER_ERROR,
  UPDATE_USER_STATUS_ERROR,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_SUCCESS
} from './userUtils'
import ArchiveUserDialog from './ArchiveUserDialog'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_MUTATION } from '../../mutations/updateUser'
import { CHANGE_USER_STATE_MUTATION } from '../../mutations/changeUserState'
import { getStatusValue } from '../../gridConfig/usersGridConfig'
import ChangePasswordDialog from './ChangePasswordDialog'
import Can, { useCan } from '../Can'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  wrapper: {
    display: 'grid',
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: '#ffffff',
    height: '510px',
    borderRadius: '6px',
    margin: '30px auto 0',
    overflowY: 'auto'
  },
  form: {
    width: '100%'
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [`@media (max-width: ${breakpoints.sm})`]: {
      gridTemplateColumns: 'auto'
    }
  },
  fieldsLeft: {
    display: 'grid',
    flexBasis: '50%',
    gridTemplateRows: 'repeat(4, 50px)',
    rowGap: '40px',
    padding: '20px'
  },
  fieldsRight: {
    display: 'grid',
    flexBasis: '50%',
    rowGap: '40px',
    padding: '20px',
    gridTemplateRows: '150px 50px 100px'
  },
  buttons: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '93%'
  }
})

const UserInfo = ({
  user,
  setHeader,
  isUserProfile,
  setIsBlocked,
  isBlocked,
  canEdit
}) => {
  const sharedClasses = useSharedStyles()
  const { verifyRoles } = useCan()
  const [
    isShowingArchiveUserConfirm,
    setIsShowingArchiveUserConfirm
  ] = useState(false)
  const [
    isShowingChangePasswordConfirm,
    setIsShowingChangePasswordConfirm
  ] = useState(false)
  const [changeUserState, { loading: isActivating }] = useMutation(
    CHANGE_USER_STATE_MUTATION,
    {
      onCompleted: result => {
        if (result?.update_User?.returning?.length > 0) {
          setIsBlocked(result.update_User.returning[0].isDisplayBlocked)
          toaster.success(UPDATE_USER_STATUS_SUCCESS)
        }
      },
      onError: _ => toaster.danger(UPDATE_USER_STATUS_ERROR)
    }
  )
  const [updateUser, { loading: isSubmitting }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: result => {
        if (result?.update_User?.returning?.length > 0) {
          const currentUser = result.update_User.returning[0]
          setHeader(currentUser)
          toaster.success(UPDATE_USER_SUCCESS)
          reset({
            firstName: currentUser.givenName,
            lastName: currentUser.familyName,
            email: currentUser.email,
            userRole: currentUser.cachedRoles
          })
        }
      },
      onError: _ => toaster.danger(UPDATE_USER_ERROR)
    }
  )
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(userValidationSchema)
  })
  const { isValid, isDirty } = useFormState({ control })

  const onSubmit = data => {
    updateUser({
      variables: {
        userId: user.id,
        firstName: data.firstName,
        lastName: data.lastName,
        notes: data.notes
      }
    })
  }

  const handleActivateUser = event => {
    event.preventDefault()
    const buttonText = event.currentTarget.innerHTML
    const isActivating = buttonText === ACTIVATE_USER
    if (isActivating) {
      activateUser(isActivating)
    } else {
      setIsShowingArchiveUserConfirm(true)
    }
  }

  const activateUser = async isActivating => {
    if (verifyRoles(SYSTEM_ADMIN)) {
      changeUserState({
        variables: {
          userId: user.id,
          isBlocked: !isActivating
        }
      })
    } else {
      toaster.warning(UPDATE_STATUS_PERMISSIONS_ERROR)
    }
  }

  const handleCloseArchiveUserConfirm = () =>
    setIsShowingArchiveUserConfirm(false)
  const handleCloseChangePasswordConfirm = () =>
    setIsShowingChangePasswordConfirm(false)
  const cachedRole = getHighestRole(user)

  const resetPassword = () => {
    changePassword(user.email, (err, result) => {
      if (err) {
        toaster.danger(err.message)
      } else {
        toaster.success(result)
        handleCloseChangePasswordConfirm()
        logout()
      }
    })
  }

  useEffect(() => {
    setValue(
      'status',
      getStatusValue({ data: { isDisplayBlocked: isBlocked } })
    )
  }, [setValue, isBlocked])

  const disableSubmit = !isDirty || !isValid || isBlocked || !canEdit

  return (
    <Pane className={sharedClasses.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Pane
          backgroundColor={myTheme.colors.blueNavy}
          padding={30}
          display="flex"
          borderTopLeftRadius="6px"
          borderTopRightRadius="6px"
        >
          <Heading color="#fff" size={600}>
            User Details
          </Heading>
        </Pane>
        <Pane className={sharedClasses.fieldsWrapper}>
          <Pane className={sharedClasses.fieldsLeft}>
            <InputTextField
              control={control}
              name="firstName"
              defaultValue={user.givenName || ''}
              required
              label="First Name"
              disabled={!canEdit}
              showErrorMessage
            />
            <InputTextField
              control={control}
              name="lastName"
              defaultValue={user.familyName || ''}
              required
              label="Last Name"
              disabled={!canEdit}
              showErrorMessage
            />
            <InputTextField
              control={control}
              name="email"
              defaultValue={user.email || ''}
              disabled
              label="Email"
            />
            <InputWithTooltip
              control={control}
              name="userRole"
              defaultValue={cachedRole}
              disabled
              label="Role"
              tooltipPosition={Position.RIGHT}
              tooltipIcon={<InfoSignIcon />}
              tooltipContent="Available roles are 'Data Admin', 'Developer', 'Rater', 'Realm Admin' or 'System Admin'"
            />
          </Pane>
          <Pane className={sharedClasses.fieldsRight}>
            <TextAreaField
              control={control}
              name="notes"
              placeholder="Enter notes"
              label="Notes"
              disabled={!canEdit}
              inputHeight={140}
              showErrorMessage
              defaultValue={user.notes || ''}
            />
            <InputWithTooltip
              control={control}
              name="status"
              defaultValue=""
              disabled
              label="Status"
              tooltipPosition={Position.RIGHT}
              tooltipIcon={<InfoSignIcon />}
              tooltipContent="Archived users are uneditable and are disabled from logging in to TabCAT. User connections to TabCAT data remain intact."
            />
            <Pane className={sharedClasses.buttons}>
              {isUserProfile && (
                <Button
                  onClick={() => setIsShowingChangePasswordConfirm(true)}
                  type="button"
                  className={sharedClasses.buttonStylesTerciary}
                >
                  Change password
                </Button>
              )}
              {!isUserProfile && (
                <Can roles={SYSTEM_ADMIN}>
                  <Button
                    className={
                      isBlocked
                        ? sharedClasses.buttonStylesSecondary
                        : sharedClasses.buttonStylesDanger
                    }
                    isLoading={isActivating}
                    onClick={handleActivateUser}
                  >
                    {isBlocked ? 'Activate User' : 'Archive User'}
                  </Button>
                </Can>
              )}
              <Button
                className={sharedClasses.buttonStyles}
                type="submit"
                isLoading={isSubmitting}
                disabled={disableSubmit}
              >
                Save changes
              </Button>
            </Pane>
          </Pane>
        </Pane>
      </form>
      {isShowingArchiveUserConfirm && (
        <ArchiveUserDialog
          isShown={isShowingArchiveUserConfirm}
          handleCloseModal={handleCloseArchiveUserConfirm}
          handleConfirm={() => {
            activateUser(false)
            setIsShowingArchiveUserConfirm(false)
          }}
          isLoading={isActivating}
        />
      )}
      {isShowingChangePasswordConfirm && (
        <ChangePasswordDialog
          isShown={isShowingChangePasswordConfirm}
          handleCloseModal={handleCloseChangePasswordConfirm}
          handleConfirm={resetPassword}
        />
      )}
    </Pane>
  )
}

export default UserInfo
