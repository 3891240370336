import { useQuery } from '@apollo/client'
import { Pane, toaster } from 'evergreen-ui'
import React, { useEffect, useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { GET_SITES_QUERY } from '../queries/getSites'
import { formStyles, tableSectionStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import { REALM_ADMIN, SYSTEM_ADMIN } from '../utils/constants'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'

const useSharedStyles = createUseStyles({
  ...formStyles,
  ...tableSectionStyles,
  sites: {
    height: '330px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      backgroundColor: `${myTheme.colors.white}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '10px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& .label_title': {
      fontWeight: 'bold'
    }
  },
  sitesPaneWrapper: {
    '& span': {
      marginLeft: '-10px'
    }
  },
  sitesPaneBody: {
    composes: '$tableRow',
    textAlign: 'left',
    padding: '10px',
    '&:hover': {
      background: `${myTheme.colors.lightBlue}`
    }
  },
  sitesPaneBodyVisited: {
    composes: '$sitesPaneBody',
    background: `${myTheme.colors.lightBlue}`
  },
  siteDisabled: {
    composes: '$checkboxSelected',
    width: '88%',
    marginLeft: '57px',
    marginTop: '10px',
    fontSize: '12px',
    '& span': {
      padding: '12px !important',
      letterSpacing: '0.018rem',
      lineHeight: '16px'
    }
  }
})

const SiteLabel = ({ field }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane
      display="flex"
      flexDirection="column"
      className={sharedClasses.sitesPaneWrapper}
    >
      <span className="label_title">{field?.site?.name}</span>
      {field?.site?.Study_Sites?.map(({ Study }) => (
        <Pane
          className={
            field.value
              ? sharedClasses.sitesPaneBodyVisited
              : sharedClasses.sitesPaneBody
          }
          key={Study.id}
        >
          {Study.name}
        </Pane>
      ))}
    </Pane>
  )
}

const SITE_STUDY_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the site and study lists of checkboxes, please try again later.'

const SiteStudyList = ({ control, currentSites = [], realmId, role }) => {
  const sharedClasses = useSharedStyles()
  const { append, fields } = useFieldArray({
    control,
    name: `sites_${realmId}`
  })
  const description = useMemo(() => {
    switch (role) {
      case SYSTEM_ADMIN:
        return 'Assign user to studies and sites in the realm. This is optional for System Admins.'
      case REALM_ADMIN:
        return 'Realm Admins are automatically assigned to all sites and studies in the realm.'
      default:
        return 'Assign user to sites and studies in your realm.'
    }
  }, [role])
  const siteChecks = useWatch({ control, name: `sites_${realmId}` })
  const controlledInputs = useMemo(
    () =>
      siteChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...siteChecks[idx]
      })),
    [fields, siteChecks]
  )

  const { loading, data: sitesStudies } = useQuery(GET_SITES_QUERY, {
    skip: !realmId,
    variables: {
      siteWhere: {
        realm_id: { _eq: realmId },
        isBlocked: { _eq: false }
      }
    },
    onError: _ => toaster.danger(SITE_STUDY_LIST_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (
      sitesStudies?.Site &&
      sitesStudies.Site.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      const isRealmAdmin = role === REALM_ADMIN
      append(
        sitesStudies.Site.map(site => ({
          site,
          value:
            isRealmAdmin || currentSites?.some(s => s.id === site.id) || false,
          disabled: isRealmAdmin || false
        }))
      )
    }
  }, [sitesStudies])

  return (
    <Pane position="relative">
      <p>{description}</p>
      {loading && <LoadingSpinner />}
      <Pane className={sharedClasses.sites}>
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            {field.disabled ? (
              <Pane className={sharedClasses.siteDisabled}>
                <SiteLabel field={field} />
              </Pane>
            ) : (
              <CheckboxField
                control={control}
                label={<SiteLabel field={field} />}
                defaultValue={field.value}
                name={`sites_${realmId}.${index}.value`}
                data-testid={`${field.site.id}-site-check`}
                className={
                  field.value
                    ? sharedClasses.checkboxSelected
                    : sharedClasses.checkbox
                }
              />
            )}
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default SiteStudyList
