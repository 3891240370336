import { Button, Dialog, Pane } from 'evergreen-ui'
import React, { useEffect, useState, useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { createUseStyles } from 'react-jss'
import { useGlobalStore } from '../hooks/useGlobalStore'
import { customListStyles } from '../styles/sharedComponentStyles'
import { logout } from '../utils/auth'
import { PROMPT_NOT_MODIFY } from '../utils/constants'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  buttons: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '93%'
  },
  textWrapper: {
    margin: '30px',
    '& h3': {
      fontSize: '38px'
    }
  }
})

// This value is used by the package react-idle-timer. After 19 minutes with no movements the IdleWarning pop up will be shown
const TIMEOUT_IN_MILLISECONDS = 1000 * 60 * 19 
// If 19 minutes pass the component IdleWarning will be shown, which runs its own
// 1 minute timer regardless of movements or keys being pressed
const TIME_REMAINING_LIMIT_IN_SECONDS = 60

const IdleWarning = ({ isShown, handleCloseModal, logout }) => {
  const sharedClasses = useSharedStyles()
  const [seconds, setSeconds] = useState(TIME_REMAINING_LIMIT_IN_SECONDS)
  const logoutCallback = useCallback(() => logout(), [logout]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(state => state - 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (seconds === 0) {
      logoutCallback()
    }
  }, [logoutCallback, seconds])

  return (
    <Dialog
      isShown={isShown}
      title="Session Timeout"
      shouldCloseOnEscapePress={false}
      hasCancel={false}
      hasClose={false}
      hasFooter={false}
    >
      <Pane className={sharedClasses.textWrapper}>
        <p>Your online session will expire in</p>
        <h3>{seconds} Seconds</h3>
        <p>
          Please click "Continue" to keep working or click "Log Out" to end your
          session now
        </p>
      </Pane>
      <Pane className={sharedClasses.buttons}>
        <Button
          className={sharedClasses.buttonStyles}
          onClick={handleCloseModal}
        >
          Continue
        </Button>
        <Button
          className={sharedClasses.buttonStylesSecondary}
          onClick={logout}
        >
          Log Out
        </Button>
      </Pane>
    </Dialog>
  )
}

export default function IdleWrapper({ children }) {
  const { setShowPrompt, isEditing } = useGlobalStore()
  const [showModal, setShowModal] = useState(false)

  const handleOnIdle = async () => {
    setShowPrompt(false, PROMPT_NOT_MODIFY)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowPrompt(isEditing)
    setShowModal(false)
  }

  useIdleTimer({
    timeout: TIMEOUT_IN_MILLISECONDS,
    onIdle: handleOnIdle,
    onActive: e => {},
    onAction: e => {},
    debounce: 500,
    events: [
      'DOMMouseScroll',
      'keydown',
      'touchstart',
      'touchmove',
      'mousewheel',
      'wheel',
      'mousedown',
      'MSPointerDown'
    ]
  })

  return (
    <>
      {showModal && (
        <IdleWarning
          isShown={showModal}
          handleCloseModal={handleCloseModal}
          logout={logout}
        />
      )}
      {children}
    </>
  )
}
