import { Button, Menu, Popover, Position } from 'evergreen-ui'
import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { HeaderContext } from '.'
import myTheme, { breakpoints } from '../../styles/theme'
import { GUIDES } from '../../utils/constants'

const useStyles = createUseStyles({
  navButtons: {
    background: `${myTheme.colors.blue} !important`,
    border: 'none',
    boxShadow: 'none',
    fontFamily: 'Roboto',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: '30px',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      boxShadow: 'none !important',
      color: '#ffffff',
      border: `1px solid #ffffff`
    },
    [`@media (max-width:${breakpoints.md})`]: {
      fontSize: '14px',
      marginRight: '0px !important',
      marginTop: '5px',
      marginBottom: '5px',
      backgroundColor: 'transparent',
      color: myTheme.colors.labelDark,
      fontWeight: '500',
      margin: 0,
      padding: 0
    }
  },
  menuNavButton: {
    composes: '$navButton',
    width: '100% !important',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontWeight: '400',
    color: myTheme.colors.labelDark,
    padding: 0,
    fontSize: '12px',
    borderBottom: `1px solid ${myTheme.colors.greyResourceBorder}`,
    '&:hover, &:active, &:focus': {
      background: 'transparent !important',
      boxShadow: 'none !important'
    }
  },
  menuItem: {
    '& span': {
      margin: 0,
      '&:hover': {
        background: myTheme.colors.labelBackground
      }
    }
  },
  submenuItem: {
    cursor: 'pointer',
    '& span': {
      color: myTheme.colors.labelDark,
      fontSize: '12px',
      fontWeight: '400'
    },
    '&:hover': {
      background: myTheme.colors.labelBackground
    }
  }
})

const ResourcesDesktop = ({ handleRealmResourceChange }) => {
  const classes = useStyles()
  const { resources } = useContext(HeaderContext)
  // resources are the top menu options, they come from useGetContentfulHeaderOptions
  // which uses the query GET_ALL_RESOURCES_QUERY

  return (
    <Popover
      minHeight={200}
      minWidth={240}
      position={Position.BOTTOM_RIGHT}
      content={
        resources ? (
          <Menu>
            <Menu.Group>
              {resources?.map(resource => {
                const options = resource.resourcePageCollection?.items || []
                return (
                  <Menu.Item
                    key={resource.title}
                    onClick={() => ''}
                    className={classes.menuItem}
                  >
                    {options?.length > 0 ? (
                      <Popover
                        position={Position.LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              {options?.map(option => (
                                <Menu.Item
                                  key={option.sys.id}
                                  className={classes.submenuItem}
                                  onClick={() => 
                                    handleRealmResourceChange({
                                      value:
                                        resource.title === GUIDES
                                          ? `guides/${option.sys.id}`
                                          : `resources/${option.sys.id}`
                                    })
                                  }
                                >
                                  {option.title}
                                </Menu.Item>
                              ))}
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Button className={classes.menuNavButton}>
                          {resource.title}
                        </Button>
                      </Popover>
                    ) : (
                      <p>{resource.title}</p>
                    )}
                  </Menu.Item>
                )
              })}
            </Menu.Group>
          </Menu>
        ) : (
          <></>
        )
      }
    >
      <Button className={classes.navButtons}>Resources</Button>
    </Popover>
  )
}

export default ResourcesDesktop
