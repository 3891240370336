import GlobalProvider from './src/components/GlobalProvider'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/100.css'
import './src/styles/global.css'
import GlobalLayout from './src/components/GlobalLayout'
// import '@datadog/browser-logs/bundle/datadog-logs'
// import '@datadog/browser-rum/bundle/datadog-rum'

export const wrapRootElement = GlobalProvider
export const wrapPageElement = GlobalLayout

export function onClientEntry(_) {

	//TODO: Remove fully if we switch to new relic.


	//TODO: Install these packages if reintroducing.
	// "@datadog/browser-logs": "^4.28.1",
    // "@datadog/browser-rum": "^4.30.1",

	// window.DD_LOGS.init({
	// 	clientToken: process.env.GATSBY_DD_LOG_CLIENT_TOKEN,
	// 	site: 'datadoghq.com',
	// 	forwardErrorsToLogs: true,
	// 	sampleRate: 100,
	// });
	// window.DD_RUM.init({
	// 	applicationId: process.env.GATSBY_DD_RUM_APPLICATION_ID,
	// 	clientToken: process.env.GATSBY_DD_RUM_CLIENT_TOKEN,
	// 	site: 'datadoghq.com',
	// 	service: 'admin-panel',
	// 	env: process.env.GATSBY_ENVIRONMENT,
	// 	// Specify a version number to identify the deployed version of your application in Datadog 
	// 	// version: '1.0.0',
	// 	sampleRate: 100,
	// 	sessionReplaySampleRate: 20,
	// 	trackInteractions: true,
	// 	trackResources: true,
	// 	trackLongTasks: true,
	// 	defaultPrivacyLevel: 'mask'
	// });
};