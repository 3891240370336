import { Checkbox, Pane } from 'evergreen-ui'
import React, { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { getError } from './helpers'

const CheckboxField = ({
  label,
  name,
  disabled,
  checked,
  defaultValue,
  control,
  onChange,
  ...rest
}) => {
  const { field, fieldState } = useController({ control, name, defaultValue })
  const isChecked = useMemo(() => {
    if (typeof checked !== 'undefined') {
      return checked
    }
    return field.value
  }, [checked, field.value])
  const { hasError } = getError(fieldState)
  return (
    <Pane>
      <Checkbox
        {...field}
        isInvalid={hasError}
        checked={isChecked}
        label={label}
        name={name}
        disabled={disabled}
        onChange={e => {
          if (onChange) {
            onChange(e)
          }
          field.onChange(e.target.checked)
        }}
        {...rest}
      />
    </Pane>
  )
}

export default CheckboxField
