import * as yup from 'yup'
import emojiRegex from 'emoji-regex'

export const userValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .typeError('Required')
    .required('Required')
    .trim()
    .test({
      name: 'firstName',
      message: 'Wrong first name value',
      test: value => {
        return !emojiRegex().test(value)
      }
    }),
  lastName: yup
    .string()
    .typeError('Required')
    .required('Required')
    .trim()
    .test({
      name: 'lastName',
      message: 'Wrong last name value',
      test: value => {
        return !emojiRegex().test(value)
      }
    }),
  notes: yup.string().test({
    name: 'notes',
    message: 'Wrong notes value',
    test: value => {
      return !emojiRegex().test(value)
    }
  })
})
