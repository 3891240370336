const myTheme = {
  colors: {
    white: '#FFFFFF',
    blue: '#132246',
    borderDark: '#707070',
    blueNavy: '#2B3D66',
    darkNavy: '#142245',
    lightBlue: '#D2E1F4',
    darkTeal: '#2B7E90',
    mediumTeal: '#D1E6EA',
    lightTeal: '#F0F4F5',
    grayTeal: '#B0B8BB',
    labelDark: '#425A70',
    labelBackground:'#F8F9F9',
    fieldBackground: '#F1F5F6',
    iconBackground: '#F1F4F5',
    errorText: '#D14343',
    errorBorder: '#ec4c47',
    warningBackground: '#FFB000',
    iconText: '#2C7E90',
    stepperBorderTitle: '#162244',
    stepperBackgroundTitle: '#D5E5E9',
    markdownParagraph: '#414d63',
    markdownBorder: '#cfd9e0',
    markdownTh: '#e7ebee',
    grey: '#6A6C75',
    greyResourceBorder: '#EDF0F2'
  }
}

export const breakpoints = {
  sm: '530px',
  md: '876px'
}

export default myTheme
