import gql from 'graphql-tag'

export const GET_ALL_RESOURCES_QUERY = gql`
  query ContentfulGetResourcesPages($preview: Boolean!) {
    resourcePageCategoryCollection(order: title_ASC, preview: $preview) {
      items {
        title
        resourcePageCollection(preview: $preview) {
          items {
            ... on ResourcePage {
              sys {
                id
              }
              title: resourcePageTitle
            }
            ... on TaskGuide {
              sys {
                id
              }
              title: taskName
            }
          }
        }
      }
    }
  }
`
