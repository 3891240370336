import { Dialog, Heading, Pane } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { modalStyles } from '../styles/sharedComponentStyles'
import QuitPopover from './QuitPopover'

const useSharedStyles = createUseStyles({
  ...modalStyles
})

const Modal = ({
  width,
  showDialog,
  handleDialogClose,
  preventBodyScrolling = true,
  children,
  shouldCloseOnOverlayClick = false,
  ...rest
}) => {
  const sharedClasses = useSharedStyles()

  return (
    <Dialog
      width={width}
      isShown={showDialog}
      onCloseComplete={handleDialogClose}
      hasHeader={false}
      hasCancel={false}
      hasFooter={false}
      preventBodyScrolling={preventBodyScrolling}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEscapePress={false}
      {...rest}
    >
      <Pane className={sharedClasses.modalSection} data-testid="modal">
        {children}
      </Pane>
    </Dialog>
  )
}

const Header = ({ title, handleCloseModal, hidePopover = false }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.modalHeader}>
      <Heading size={800} is="h4">
        {title}
      </Heading>
      <Pane position="absolute" right="5%">
        <QuitPopover
          handleCloseModal={handleCloseModal}
          hidePopover={hidePopover}
        />
      </Pane>
    </Pane>
  )
}

const Body = ({ children }) => <>{children}</>

Modal.Header = Header
Modal.Body = Body

export default Modal
