export const getPathName = () => {
  let path = window.location.pathname.replace(/\/+$/, '')
  return path[0] === '/' ? path.substr(1) : path
}

export const validateRepeatedName = (names: string[], name: string) =>
  names?.some(n => n.trim().toLowerCase() === name?.trim()?.toLowerCase())

export const getRealmIdFromURL = (url: { pathname: string }) => url?.pathname?.includes('/realm/') &&
  url?.pathname?.split('/realm/')[1]?.split('/') && url?.pathname?.split('/realm/')[1]?.split('/')[0]

export const getRealmPathFromURL = (url: { pathname: string }) => url?.pathname?.includes('/realm/') &&
  url?.pathname?.split('/realm/')[1]?.split('/') && url?.pathname?.split('/realm/')[1]?.split('/')[1]