import _ from 'lodash'
import React from 'react'
import { getUserRole } from '../utils/auth'

export const useCan = () => {
  const userLoggedRoles = getUserRole()

  const verifyRoles = permittedRoles => {
    if (
      (_.isArray(permittedRoles) &&
        permittedRoles.includes(userLoggedRoles)) ||
      (!_.isArray(permittedRoles) && userLoggedRoles.includes(permittedRoles))
    ) {
      return true
    }
    return false
  }

  return { verifyRoles }
}

const Can = ({ roles, children }) => {
  const { verifyRoles } = useCan()
  if (verifyRoles(roles)) {
    return <>{children}</>
  }
  return null
}

export default Can
