import React from 'react'
import { Pane, Spinner } from 'evergreen-ui'

const LoadingSpinner = () => (
  <Pane
    position="absolute"
    top="200px"
    bottom={0}
    left="50%"
    transform="translateX(-50%)"
    right={0}
    zIndex={100}
    width="40px"
    data-testid='spinner'
  >
    <Spinner size={40} margin={15} />
  </Pane>
)

export default LoadingSpinner
