import gql from 'graphql-tag'

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $userId: String!
    $firstName: String
    $lastName: String
    $notes: String
  ) {
    update_User(
      where: { id: { _eq: $userId } }
      _set: { familyName: $lastName, givenName: $firstName, notes: $notes }
    ) {
      returning {
        id
        givenName
        familyName
        notes
        name
        cachedRoles
        email
      }
    }
  }
`
