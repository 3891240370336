import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { GET_ALL_RESOURCES_QUERY } from '../queries/getResources'

const GET_RESOURCES_TITLES_ERROR_MESSAGE =
  'Sorry, something went wrong getting resources names, please try again later.'

export const useGetContentfulHeaderOptions = (isPreview: boolean) => {
  const { data: resources } = useQuery(GET_ALL_RESOURCES_QUERY, {
    variables: {
      preview: isPreview
    },
    onError: _ => toaster.danger(GET_RESOURCES_TITLES_ERROR_MESSAGE)
  })

  const resourcesOptions = resources?.resourcePageCategoryCollection?.items

  return { resourcesOptions }
}
