import React from 'react'
import { Pane, Tooltip } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import InputTextField from './InputTextField'
import myTheme from '../../styles/theme'

const useSharedStyles = createUseStyles({
  inputWrapper: {
    position: 'relative',
    '& svg[aria-describedby*="tooltip"]': {
      position: 'absolute',
      right: '10%',
      top: '40px',
      color: `${myTheme.colors.grey}`
    }
  }
})

const InputWithTooltip = ({
  tooltipContent,
  tooltipPosition,
  tooltipIcon,
  ...props
}) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.inputWrapper}>
      <InputTextField {...props} />
      <Tooltip position={tooltipPosition} content={tooltipContent}>
        {tooltipIcon}
      </Tooltip>
    </Pane>
  )
}

export default InputWithTooltip
