import { useQuery } from '@apollo/client'
import { Pane, toaster } from 'evergreen-ui'
import React, { useEffect, useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { GET_ALL_REALMS_QUERY } from '../queries/getAllRealms'
import { formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import { SYSTEM_ADMIN } from '../utils/constants'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'

const useSharedStyles = createUseStyles({
  ...formStyles,
  realms: {
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '6px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& span': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    }
  }
})

const REALM_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the realm list of checkboxes, please try again later.'

const RealmList = ({ control, currentRealms = [], role }) => {
  const sharedClasses = useSharedStyles()
  const { append, fields } = useFieldArray({ control, name: 'realms' })
  const realmChecks = useWatch({ control, name: 'realms' })
  const controlledInputs = useMemo(
    () =>
      realmChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...realmChecks[idx]
      })),
    [fields, realmChecks]
  )

  const description = useMemo(() => {
    switch (role) {
      case SYSTEM_ADMIN:
        return 'Assign user to realms in TabCAT. This is optional for System Admins.'
      default:
        return 'Assign user to realms in TabCAT.'
    }
  }, [role])

  const { loading, data: realms } = useQuery(GET_ALL_REALMS_QUERY, {
    onError: _ => toaster.danger(REALM_LIST_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (
      realms?.Realm &&
      realms.Realm.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      append(
        realms.Realm.map(realm => ({
          realm,
          value: currentRealms.some(r => r.id === realm.id) || false
        }))
      )
    }
  }, [realms])

  return (
    <Pane position="relative">
      <p>{description}</p>
      {loading && <LoadingSpinner />}
      <Pane className={sharedClasses.realms}>
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            <CheckboxField
              control={control}
              label={field.realm.name}
              defaultValue={field.value}
              name={`realms.${index}.value`}
              data-testid={`${field.realm.id}-realm-check`}
              className={
                field.value
                  ? sharedClasses.checkboxSelected
                  : sharedClasses.checkbox
              }
            />
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default RealmList
