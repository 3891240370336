import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pane } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import date from 'date-and-time'
import { navStyles } from '../../styles/sharedComponentStyles'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import { navigate } from 'gatsby'

const useSharedStyles = createUseStyles({
  ...navStyles
})

const UserBanner = ({ user, title }) => {
  const { realmId } = useGlobalStore()
  const sharedClasses = useSharedStyles()
  return (
    <nav className={sharedClasses.nav}>
      <Pane className={sharedClasses.secondaryNav}>
        <h1 data-testid="user-details-title">
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={() => {
              if (typeof window !== 'undefined') {
                navigate(`/realm/${realmId}/users`)
              }
            }}
          />
          {title}
          <span>
            Created on {date.format(new Date(user.createdAt), 'MM/DD/YYYY')}
          </span>
        </h1>
      </Pane>
    </nav>
  )
}

export default UserBanner
