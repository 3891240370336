import { Pane } from 'evergreen-ui'
import React from 'react'

export default function Welcome({ text }) {
  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <h2>{text || 'Welcome...'}</h2>
    </Pane>
  )
}
