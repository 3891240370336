import _ from 'lodash'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { PROMPT_MODIFY } from '../utils/constants'

export const realmDataStore = create(
  persist(
    devtools(set => ({
      realmData: [],
      encounterId: null,
      realmId: null,
      setRealmId: id => set({ realmId: id }),
      realmPath: '', 
      setRealmPath: path => set({ realmPath: path }),
      setEncounterId: id => set({ encounterId: id }),
      setRealmData: data =>
        set({ realmData: _.isArray(data) ? [...data] : [] }),
      //currentRealm: '',
      //setCurrentRealm: realm => set({ currentRealm: realm })
    })),
    {
      name: 'tabcat-realm'
    }
  )
)

export const userDataStore = create(
  devtools(set => ({
    userData: null,
    setUserData: user => set({ userData: user })
  }))
)

export const beforeUnloadDataStore = create(
  devtools(set => ({
    showPrompt: false,
    isEditing: false,
    setShowPrompt: (show, mode = PROMPT_MODIFY) =>
      set(state => {
        return {
          showPrompt: show,
          isEditing: mode === PROMPT_MODIFY ? show : state.isEditing
        }
      })
  }))
)

export const contentfulDataStore = create(
  devtools(set => ({
    isPreview: process.env.GATSBY_USE_CONTENTFUL_PREVIEW === 'true' || false
  }))
)

export const sideSheetDataStore = create(
  devtools(set => ({
    shouldCloseSideSheet: false,
    sideSheetIsOpen: false,
    setShouldCloseSideSheet: shouldCloseSideSheet =>
      set({ shouldCloseSideSheet }),
    setSideSheetIsOpen: sideSheetIsOpen => set({ sideSheetIsOpen })
  }))
)
