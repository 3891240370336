import React from 'react'
import { Button, Menu, Popover, Position } from 'evergreen-ui'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import { navRealmPages } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import myTheme, { breakpoints } from '../../styles/theme'
import { createUseStyles } from 'react-jss'
import ResourcesMobile from './ResourcesMobile'
import { getRealmIdFromURL } from '../../utils/filters'
import { navigate } from 'gatsby'
import { useRoleAccess } from '../../hooks/useRoleAccess'

const useStyles = createUseStyles({
  optionsTabletButton: {
    display: 'none',
    alignSelf: 'center',
    background: myTheme.colors.blue,
    color: myTheme.colors.white,
    fontSize: '20px',
    [`@media (max-width:${breakpoints.md})`]: {
      display: 'inline'
    }
  },
  usernameWrapper: {
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  username: {
    display: 'inline',
    color: myTheme.colors.white,
    fontSize: '15px',
    marginRight: '10px',
    minWidth: '130px',
    height: '100px',
    '&:focus': {
      boxShadow: 'none !important'
    },
    [`@media (max-width:${breakpoints.md})`]: {
      color: myTheme.colors.labelDark,
      height: 'initial',
      '&:hover, &:active': {
        backgroundColor: 'transparent !important'
      }
    }
  }
})

const HeaderOptionsMobile = ({
  handleRealmResourceChange,
  handleOpenUserDetails,
  handleLogout
}) => {
  const classes = useStyles()
  const { userData, realmId } = useGlobalStore()
  const { canExportData } = useRoleAccess()
  const routeRealm = window?.location
    ? Number(getRealmIdFromURL(window.location))
    : -1

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Group>
            {userData && (
              <Menu.Item className={classes.usernameWrapper}>
                <Button
                  className={classes.username}
                  onClick={handleOpenUserDetails}
                  disabled={!userData}
                  appearance="minimal"
                >
                  {`${userData.givenName || ''} ${userData.familyName || ''}`}
                </Button>
              </Menu.Item>
            )}
          </Menu.Group>

          <Menu.Group title="Realm">
            {navRealmPages.map(label => (
              <Menu.Item
                key={label}
                onClick={() => handleRealmResourceChange({ value: label })}
              >
                {label}
              </Menu.Item>
            ))}
          </Menu.Group>

          <Menu.Divider />

          {canExportData && (
            <Menu.Group>
              <Menu.Item
                onClick={() =>
                  handleRealmResourceChange({ value: 'data-export' })
                }
              >
                Data Export
              </Menu.Item>
            </Menu.Group>
          )}

          {realRealmId > 0 && (
            <>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item
                  onClick={() =>
                    handleRealmResourceChange({ value: 'encounters' })
                  }
                >
                  Encounters
                </Menu.Item>
              </Menu.Group>
            </>
          )}
          <Menu.Divider />
          <ResourcesMobile
            handleRealmResourceChange={handleRealmResourceChange}
          />
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item textAlign="center" onClick={handleLogout}>
              Log Out
            </Menu.Item>
          </Menu.Group>
        </Menu>
      }
    >
      <Button
        marginRight={16}
        className={classes.optionsTabletButton}
        appearance="minimal"
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
    </Popover>
  )
}

export default HeaderOptionsMobile
