import { useMemo } from 'react'
import { useCan } from '../components/Can'
import { getHighestRole } from '../components/UserDetails/userUtils'
import {
  DATA_ADMIN,
  RATER,
  REALM_ADMIN,
  SYSTEM_ADMIN
} from '../utils/constants'

export const useUserAccess = (user, isUserProfile, isBlocked) => {
  /** This is used in UserDetails component only to control who could edit the user page */
  const { verifyRoles } = useCan()
  const canEditUserAsRealmAdmin = () => {
    return (
      verifyRoles(REALM_ADMIN) &&
      ([DATA_ADMIN, RATER].includes(getHighestRole(user)) || isUserProfile)
    )
  }

  const canEditUser = useMemo(() => {
    if (!isBlocked) {
      return verifyRoles(SYSTEM_ADMIN) || canEditUserAsRealmAdmin()
    }
    return false
  }, [isBlocked, isUserProfile, user])

  const imRealmAdmin = verifyRoles(REALM_ADMIN) && isUserProfile

  return { canEditUser, imRealmAdmin }
}

export const useRoleAccess = () => {
  const { verifyRoles } = useCan()

  const canEditStudy = useMemo(
    () => verifyRoles(SYSTEM_ADMIN) || verifyRoles(REALM_ADMIN),
    []
  )

  const canEditSite = useMemo(
    () => verifyRoles(SYSTEM_ADMIN) || verifyRoles(REALM_ADMIN),
    []
  )

  const canOpenUserDetails = verifyRoles([
    REALM_ADMIN,
    SYSTEM_ADMIN,
    DATA_ADMIN
  ])

  const canOpenEncounterDetails = verifyRoles([
    REALM_ADMIN,
    SYSTEM_ADMIN,
    DATA_ADMIN
  ])

  const canExportData = verifyRoles([REALM_ADMIN, SYSTEM_ADMIN, DATA_ADMIN])

  return {
    canEditSite,
    canEditStudy,
    canExportData,
    canOpenEncounterDetails,
    canOpenUserDetails
  }
}
