import gql from 'graphql-tag'

export const GET_REALM_USER_BY_ID_QUERY = gql`
  query RealmUserByIdQuery($userId: String!) {
    User_by_pk(id: $userId) {
      familyName
      givenName
      email
      createdAt
      id
      name
      notes
      isDisplayBlocked
      cachedRoles
      didAcceptTermsAndConditions
      lastAcceptedTermsAndConditionsVersion
      Realm_Users(order_by: { Realm: { name: asc } }) {
        Realm {
          id
          name
          isBlocked
        }
      }
      Site_Users(where: { Site: { isBlocked: { _eq: false } } }) {
        Site {
          name
          id
          isBlocked
          realm_id
          Study_Sites(where: { Study: { isBlocked: { _eq: false } } }) {
            Study {
              Realm {
                name
              }
              id
              name
              realm_id
              isBlocked
            }
          }
        }
      }
    }
  }
`
