import gql from 'graphql-tag'

export const ACCEPT_TERMS_AND_CONDITIONS_MUTATION = gql`
  mutation AcceptTermsAndConditions($userId: String!, $version: Int) {
    update_User(
      where: { id: { _eq: $userId } }
      _set: {
        didAcceptTermsAndConditions: true
        lastAcceptedTermsAndConditionsVersion: $version
      }
    ) {
      returning {
        id
        isDisplayBlocked
        familyName
        givenName
        didAcceptTermsAndConditions
        lastAcceptedTermsAndConditionsVersion
        Realm_Users {
          Realm {
            id
            name
          }
        }
      }
    }
  }
`
