import React, { createContext, useState } from 'react'
import { navigate } from 'gatsby'
import { createUseStyles } from 'react-jss'
import HeaderLogoWrapper from './HeaderLogoWrapper'
import HeaderOptionsDesktop from './HeaderOptionsDesktop'
import HeaderOptionsMobile from './HeaderOptionsMobile'
import myTheme from '../../styles/theme'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import { logout } from '../../utils/auth'
import UserDetailsWrapper from '../UserDetails'
import { useGetContentfulHeaderOptions } from '../../hooks/useGetContentfulHeaderOptions'
import { ALL_REALMS_ID } from '../../utils/constants'

const useStyles = createUseStyles({
  navBar: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    backgroundColor: myTheme.colors.blue,
    alignItems: 'stretch',
    fontWeight: 400,
    height: '100px',
    color: '#ffffff',
    zIndex: 10,
    '& a': {
      color: '#ffffff',
      textDecoration: 'none',
      marginRight: '30px'
    }
  }
})

export const HeaderContext = createContext({})

const Header = ({ mainContainerRef }) => {
  const classes = useStyles()
  const {
    //setCurrentRealm,
    setRealmId,
    userData,
    isPreview
  } = useGlobalStore()
  const [showUserDetails, setShowUserDetails] = useState(false)
  const { resourcesOptions } = useGetContentfulHeaderOptions(isPreview)

  const handleLogout = e => {
    e.preventDefault()
    logout()
  }

  // Realms are set here
  const handleCurrentRealmChange = (id, realmPath) => {
    /*     const isInStudyCurrently = location?.pathname?.includes('/studies')
    if (isInStudyCurrently && sideSheetIsOpen) {
      // If the sidesheet is open and the realm change the sidesheet should be closed
      setShouldCloseSideSheet(true)
    } */

    if (id === ALL_REALMS_ID) {
      navigate('/realms')
    } else {
      if (realmPath && realmPath !== '') {
        navigate(`/realm/${id}/${realmPath}`)
      } else {
        navigate(`/realm/${id}/studies`)
      }
    }

    setRealmId(Number(id))
  }

  const handleRealmResourceChange = (item, realmId) => {
    if (item.value) {
      if (item.value.toLowerCase() === 'realm') {
        navigate('/')
      } else if (
        item.value.includes('guides') ||
        item.value.includes('resources')
      ) {
        navigate(`/${item.value}`)
      } else {
        if (realmId) {
          navigate(`/realm/${realmId}/${item.value.toLowerCase()}`)
        } else {
          navigate(`/${item.value.toLowerCase()}`)
        }
      }
    }
  }

  const handleOpenUserDetails = () => {
    mainContainerRef?.current?.classList.add('hide')
    setShowUserDetails(true)
  }

  return (
    <HeaderContext.Provider
      value={{
        resources: resourcesOptions
      }}
    >
      <nav className={classes.navBar}>
        <HeaderLogoWrapper
          handleCurrentRealmChange={handleCurrentRealmChange}
        />
        <HeaderOptionsDesktop
          handleOpenUserDetails={handleOpenUserDetails}
          handleRealmResourceChange={handleRealmResourceChange}
          handleLogout={handleLogout}
        />
        <HeaderOptionsMobile
          handleRealmResourceChange={handleRealmResourceChange}
          handleOpenUserDetails={handleOpenUserDetails}
          handleLogout={handleLogout}
        />
      </nav>
      {showUserDetails && userData && (
        <UserDetailsWrapper
          userId={userData.id}
          show={showUserDetails}
          isUserProfile
          parentSectionRef={mainContainerRef}
          handleDetailsClose={setShowUserDetails}
        />
      )}
    </HeaderContext.Provider>
  )
}

export default Header
