import {
  ApolloClient,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache
} from '@apollo/client'
//import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { getProfile, getToken, getUserRole, silentAuth } from './src/utils/auth'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'
import { UNAUTHENTICATED } from './src/utils/constants'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

// THIS IS THE REAL GRAPHQL CLIENT, THE OTHER ONE IS JUST FOR TESTING

const cache = new InMemoryCache()

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const httpLink = new HttpLink({
  uri: process.env.GATSBY_HASURA_GRAPHQL_URL,
  fetch
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GATSBY_HASURA_GRAPHQL_WS_URL,
    connectionParams: async () => {
      // Await 2 seconds for the connection to start
      await new Promise(r => setTimeout(r, 2000))
      const token = await getToken()
      const user = getProfile()
      const role = getUserRole(user)
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          'x-hasura-role': role
        }
      }
    },
    webSocketImpl: require('websocket').w3cwebsocket
  })
)

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions?.code) {
          case UNAUTHENTICATED: {
            let request$
            if (!isRefreshing) {
              isRefreshing = true
              request$ = fromPromise(
                silentAuth()
                  .then(authResult => {
                    resolvePendingRequests()
                    return authResult
                  })
                  .catch(error => {
                    pendingRequests = []
                    return
                  })
                  .finally(() => {
                    isRefreshing = false
                  })
              ).filter(value => Boolean(value))
            } else {
              // Will only emit once the Promise is resolved
              request$ = fromPromise(
                new Promise(resolve => {
                  pendingRequests.push(() => resolve())
                })
              )
            }
            return request$.flatMap(() => {
              return forward(operation)
            })
          }
          default: {
            const errorText = `[GraphQL error]: Message: ${err.message}, code: ${err.extensions.code} Location: ${err.locations}, Path: ${err.path}`
            console.error(errorText)
          }
        }
      }
    }
    if (networkError) {
      const err = `[Network error]: ${operation.operationName} ${networkError}`
      console.error(err)
    }
  }
)

const apolloAuthContext = setContext((_, { headers }) => {
  const jwt_token = getToken()
  const user = getProfile()
  const role = getUserRole(user)

  return {
    headers: {
      ...headers,
      'x-hasura-role': role,
      Authorization: jwt_token ? `Bearer ${jwt_token}` : null
    }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  from([errorLink, apolloAuthContext, wsLink]),
  from([errorLink, apolloAuthContext, httpLink])
)

export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: link,
  cache
})
