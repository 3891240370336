import _ from 'lodash'
import {
  DATA_ADMIN,
  DEVELOPER,
  RATER,
  REALM_ADMIN,
  SYSTEM_ADMIN
} from '../../utils/constants'

export const UPDATE_USER_SUCCESS = 'User data updated successfully.'
export const UPDATE_USER_STATUS_SUCCESS = 'User status updated.'
export const UPDATE_USER_ERROR =
  'Sorry, something went wrong saving the user data.'
export const GET_USER_ROLES_ERROR =
  'Sorry, something went wrong returning the user role.'
export const UPDATE_USER_STATUS_ERROR =
  'Sorry, there was an error updating the user status.'
export const UPDATE_STATUS_PERMISSIONS_ERROR =
  'Sorry, you must be a System Admin.'
export const UPDATE_REALM_ACCESS_ERROR =
  'Sorry, something went wrong updating the access to the realm, please try again.'
export const UPDATE_SITE_ACCESS_ERROR =
  'Sorry, something went wrong updating the access to the site, please try again.'
export const GET_USERS_ERROR_MESSAGE =
  'Sorry, something went wrong getting the users list, please try again.'
export const GET_USER_ERROR_MESSAGE =
  'Sorry, something went wrong getting the user details, please try again.'

export const getTitle = user => {
  return `${user?.givenName || ''} ${user?.familyName || ''}`
}

export const getHighestRole = user => {
  const rolesOrdered = [DEVELOPER, RATER, DATA_ADMIN, REALM_ADMIN, SYSTEM_ADMIN]
  const rolesSorted = _.sortBy(
    user?.cachedRoles?.split(','),
    e => rolesOrdered.findIndex(r => r === e) * -1
  )
  return rolesSorted?.[0] || ''
}
