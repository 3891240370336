import React from 'react'
import { Pane, Button, Popover } from 'evergreen-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import myTheme from '../styles/theme'

const QuitPopover = ({ handleCloseModal, hidePopover }) => {
  if (hidePopover) {
    return (
      <Button
        onClick={event => {
          event.preventDefault()
          handleCloseModal()
        }}
        appearance="minimal"
      >
        <FontAwesomeIcon
          icon={faTimes}
          data-testid='close-button'
          style={{ fontSize: '16px', fill: myTheme.colors.iconText }}
        />
      </Button>
    )
  }

  return (
    <Popover
      content={({ close }) => (
        <Pane
          width={200}
          height={170}
          paddingX={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <p>Quit editing?</p>
          <p>
            <small
              style={{
                color: `${myTheme.colors.grey}`,
                textAlign: 'center'
              }}
            >
              Any changes will not be saved.
            </small>
          </p>
          <Pane display="flex" flexDirection="row">
            <Button margin={15} onClick={close}>
              Stay
            </Button>
            <Button
              margin={15}
              onClick={event => {
                event.preventDefault()
                handleCloseModal()
                close()
              }}
            >
              Quit
            </Button>
          </Pane>
        </Pane>
      )}
      shouldCloseOnExternalClick={false}
    >
      <Button appearance="minimal">
        <FontAwesomeIcon
          icon={faTimes}
          style={{ fontSize: '16px', fill: myTheme.colors.iconText }}
        />
      </Button>
    </Popover>
  )
}

export default QuitPopover
