import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Pane, SelectMenu } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { version } from '../../../autobuild_version'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import myTheme, { breakpoints } from '../../styles/theme'
import { ALL_REALMS_ID, ALL_REALMS, SYSTEM_ADMIN } from '../../utils/constants'
import { useCan } from '../Can'
import { getRealmIdFromURL } from '../../utils/filters'
import Logo from '../Logo'

const useStyles = createUseStyles({
  navLogoWrapper: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    [`@media (max-width:${breakpoints.md})`]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  navButtons: {
    background: `#2B3D66!important`,
    border: 'none',
    boxShadow: 'none',
    fontFamily: 'Roboto',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: '30px',
    backgroundColor: `#2B3D66!important`,
    '&:hover, &:focus': {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      boxShadow: 'none !important',
      color: '#ffffff',
      border: `1px solid #ffffff`
    },
    '&:active': {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      boxShadow: 'none !important',
      color: `${myTheme.colors.blue}`,
      border: `1px solid #ffffff`
    },
    [`@media (max-width:${breakpoints.md})`]: {
      fontSize: '14px',
      marginRight: '0px !important',
      marginTop: '5px',
      marginBottom: '5px',
      backgroundColor: 'transparent',
      color: myTheme.colors.labelDark,
      fontWeight: '500',
      margin: 0,
      padding: 0
    }
  },
  realmButton: {
    composes: '$navButtons',
    background: `${myTheme.colors.blueNavy}`,
    color: myTheme.colors.white,
    fontWeight: 'bold',
    minWidth: '250px',
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width:${breakpoints.md})`]: {
      minWidth: '200px'
    },
    [`@media (max-width:${breakpoints.sm})`]: {
      minWidth: '150px'
    }
  }
})

const HeaderLogoWrapper = ({ handleCurrentRealmChange }) => {
  const classes = useStyles()
  const { realmData, realmId, realmPath } = useGlobalStore()
  const { verifyRoles } = useCan()
  const realmOptions = [...realmData]
  const routeRealm = window?.location
    ? Number(getRealmIdFromURL(window.location))
    : -1

  if (verifyRoles(SYSTEM_ADMIN)) {
    realmOptions.unshift({
      id: ALL_REALMS_ID,
      name: ALL_REALMS
    })
  }

  const realmOptionsMaxCharacters = Math.max(
    ...realmOptions.map(item => item?.name?.length)
  )

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  return (
    <Pane className={classes.navLogoWrapper}>
      <Pane>
        <Logo />
        <p style={{ textAlign: 'center', margin: 0, marginTop: '-20px' }}>
          {version}
        </p>
      </Pane>
      {/* Here are the list of realms */}
      {realmOptions && (
        <SelectMenu
          options={realmOptions.map(item => ({
            label: item.name,
            value: item.id,
            id: item.id
          }))}
          selected={''}
          onSelect={item => handleCurrentRealmChange(item.id, realmPath)}
          //hasFilter={false}
          hasTitle={false}
          closeOnSelect={true}
          width={
            realmOptionsMaxCharacters && realmOptionsMaxCharacters > 60
              ? 360
              : realmOptionsMaxCharacters && realmOptionsMaxCharacters > 50
              ? 320
              : 280
          }
        >
          <Button className={classes.realmButton} data-testid="select-realm">
            {realmOptions.find(r => r.id === realRealmId)?.name}
            <FontAwesomeIcon icon={faCaretDown} />
          </Button>
        </SelectMenu>
      )}
    </Pane>
  )
}

export default HeaderLogoWrapper
