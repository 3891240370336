import gql from 'graphql-tag'

export const ADD_USER_TO_SITE_MUTATION = gql`
  mutation addUserToSite($siteId: Int!, $userId: String!) {
    insert_Site_User(objects: { site_id: $siteId, user_id: $userId }) {
      returning {
        Site {
          id
        }
        User {
          familyName
          givenName
          email
          createdAt
          id
          name
          isDisplayBlocked
          Site_Users_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
