import { useEffect, useState } from 'react'
import { getTitle } from '../components/UserDetails/userUtils'
import { useGlobalStore } from './useGlobalStore'

export function useHandleUserDetails({
  user,
  show,
  sideSheetEl,
  parentSectionRef,
  handleDetailsClose
}) {
  const { setShowPrompt } = useGlobalStore()
  const [title, setTitle] = useState(getTitle(user))
  const [showAccessManagement, setShowAccessManagement] = useState(false)
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

  function handleAccessManagementCloseModal() {
    setShowAccessManagement(false)
  }

  function handleModalAccessManagementOpenModal() {
    setShowAccessManagement(true)
  }

  function handleCloseSideSheet() {
    parentSectionRef?.current?.classList?.remove('hide')
    sideSheetEl?.current?.classList?.remove('show')
    setShowPrompt(false)
    handleDetailsClose()
  }

  function setHeader(currentUser) {
    setTitle(getTitle(currentUser))
  }

  useEffect(() => {
    if (show) {
      setShowPrompt(true)
      sideSheetEl.current.classList.add('show')
    }
    return () => setShowPrompt(false)
  }, [setShowPrompt, show, sideSheetEl])

  return {
    title,
    showAccessManagement,
    tabSelectedIndex,
    handleAccessManagementCloseModal,
    handleModalAccessManagementOpenModal,
    handleCloseSideSheet,
    setHeader,
    setTabSelectedIndex
  }
}
