import { useMutation } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  getHighestRole,
  UPDATE_REALM_ACCESS_ERROR,
  UPDATE_SITE_ACCESS_ERROR,
  UPDATE_USER_SUCCESS
} from '../components/UserDetails/userUtils'
import { ADD_USER_TO_REALM_MUTATION } from '../mutations/addUserToRealm'
import { ADD_USER_TO_SITE_MUTATION } from '../mutations/addUserToSite'
import { REMOVE_USER_FROM_REALM_MUTATION } from '../mutations/removeUserFromRealm'
import { REMOVE_USER_FROM_SITE_MUTATION } from '../mutations/removeUserFromSite'
import {
  DELETE_REF,
  processElementManagement,
  updateSiteUserCache,
  updateUserRealmCache
} from '../services/managementService'
import { SYSTEM_ADMIN } from '../utils/constants'

export const useHandleAccessManagement = ({ user, handleCloseModal }) => {
  const role = getHighestRole(user)
  const isSystemAdmin = role === SYSTEM_ADMIN
  const [step, setStep] = useState('select_realms')
  const [updateLoading, setUpdateLoading] = useState(false)
  const [shouldRequestRealms, setShouldRequestRealms] = useState(true)
  const { control, watch, handleSubmit } = useForm({
    mode: 'onChange'
  })

  const cleanupSitesFromRealm = currentRealm => {
    /**
     *  TODO: This could be a Hasura Action which removes the realm and the sites assigned for that user in that Realm
     *  */
    const sitesPerRealm =
      user.Site_Users?.filter(site => site.Site.realm_id === currentRealm.id) ||
      []
    for (const { Site } of sitesPerRealm) {
      removeSiteFromUser({
        variables: {
          userId: user.id,
          siteId: Site.id
        }
      })
    }
  }

  const [addUserToRealm] = useMutation(ADD_USER_TO_REALM_MUTATION, {
    update: (cache, { data: { insert_Realm_User } }) => {
      const currentRealm = insert_Realm_User?.returning?.[0]?.Realm
      if (currentRealm) {
        updateUserRealmCache(cache, currentRealm, user)
      }
    },
    onError: error => toaster.danger(UPDATE_REALM_ACCESS_ERROR)
  })
  const [removeUserFromRealm] = useMutation(REMOVE_USER_FROM_REALM_MUTATION, {
    update: (cache, { data: { delete_Realm_User } }) => {
      const currentRealm = delete_Realm_User?.returning?.[0]?.Realm
      if (currentRealm) {
        updateUserRealmCache(cache, currentRealm, user, DELETE_REF)
      }
    },
    onCompleted: ({ delete_Realm_User }) => {
      const currentRealm = delete_Realm_User?.returning?.[0]?.Realm
      if (currentRealm) {
        cleanupSitesFromRealm(currentRealm)
      }
    },
    onError: error => toaster.danger(UPDATE_REALM_ACCESS_ERROR)
  })
  const [addSiteToUser] = useMutation(ADD_USER_TO_SITE_MUTATION, {
    update: (cache, { data: { insert_Site_User } }) => {
      const currentSite = insert_Site_User?.returning?.[0]?.Site
      if (currentSite) {
        updateSiteUserCache(cache, currentSite, user)
      }
    },
    onError: error => toaster.danger(UPDATE_SITE_ACCESS_ERROR)
  })
  const [removeSiteFromUser] = useMutation(REMOVE_USER_FROM_SITE_MUTATION, {
    update: (cache, { data: { delete_Site_User } }) => {
      const currentSite = delete_Site_User?.returning?.[0]?.Site
      if (currentSite) {
        updateSiteUserCache(cache, currentSite, user, DELETE_REF)
      }
    },
    onError: error => toaster.danger(UPDATE_SITE_ACCESS_ERROR)
  })
  const realms = watch('realms')
  const realmsSelected = realms?.filter(r => r.value)?.map(r => r.realm) || []
  const currentRealms = user.Realm_Users.map(ru => ru.Realm)?.filter(r => r)
  const currentSites = user?.Site_Users?.map(su => su.Site)
  const disableNextRealmSelection = realmsSelected.length <= 0 && !isSystemAdmin
  const handleGoToSelectSites = () => {
    if (realmsSelected.length > 0) {
      setStep('select_sites')
      setShouldRequestRealms(false)
    } else {
      handleSubmit(saveChanges)()
    }
  }
  const handleGoToSelectRealms = () => setStep('select_realms')

  const saveRealmChanges = data => {
    const result = processElementManagement(
      data.realms.map(r => ({ id: r.realm.id, value: r.value })),
      currentRealms,
      realmId =>
        addUserToRealm({
          variables: {
            userId: user.id,
            realmId
          }
        }),
      realmId =>
        removeUserFromRealm({
          variables: {
            userId: user.id,
            realmId
          }
        })
    )
    return result
  }

  const saveSiteChanges = (data, realmId) => {
    const result = processElementManagement(
      data[`sites_${realmId}`]?.map(e => ({ ...e.site, value: e.value })),
      currentSites.filter(s => s.realm_id === realmId),
      siteId =>
        addSiteToUser({
          variables: {
            userId: user.id,
            siteId
          }
        }),
      siteId =>
        removeSiteFromUser({
          variables: {
            userId: user.id,
            siteId
          }
        })
    )
    return result
  }

  const saveChanges = data => {
    setUpdateLoading(true)
    let sitesResult = []
    const realmResult = saveRealmChanges(data)
    for (const realm of realmsSelected) {
      const siteResult = saveSiteChanges(data, realm.id)
      sitesResult = [...sitesResult, ...siteResult]
    }
    processPromisesResult([...realmResult, ...sitesResult])
  }

  const processPromisesResult = async resultPromises => {
    if (resultPromises) {
      await Promise.all(resultPromises)
      toaster.success(UPDATE_USER_SUCCESS)
    }
    setUpdateLoading(false)
    handleCloseModal()
  }

  const handleFormSubmit = handleSubmit(saveChanges)

  return {
    handleFormSubmit,
    control,
    updateLoading,
    handleGoToSelectRealms,
    handleGoToSelectSites,
    disableNextRealmSelection,
    shouldRequestRealms,
    step,
    watch,
    isSystemAdmin,
    realmsSelected,
    currentRealms,
    currentSites,
    role
  }
}
