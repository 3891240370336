import gql from 'graphql-tag'

export const CHANGE_USER_STATE_MUTATION = gql`
  mutation UserActivate($userId: String!, $isBlocked: Boolean) {
    update_User(
      where: { id: { _eq: $userId } }
      _set: { isDisplayBlocked: $isBlocked }
    ) {
      returning {
        id
        isDisplayBlocked
      }
    }
  }
`
