import gql from 'graphql-tag'

export const ADD_USER_TO_REALM_MUTATION = gql`
  mutation addUserToRealm($realmId: Int!, $userId: String!) {
    insert_Realm_User(objects: { realm_id: $realmId, user_id: $userId }) {
      returning {
        User {
          familyName
          givenName
          email
          createdAt
          id
          name
          isDisplayBlocked
        }
        Realm {
          id
        }
      }
    }
  }
`
