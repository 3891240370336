/* DEMOGRAPHICS */
export const FULL_DEMOGRAPHICS = 'FULL_DEMOGRAPHICS'
export const LIMITED_DEMOGRAPHICS = 'LIMITED_DEMOGRAPHICS'

export const QUESTIONNAIRE_DOMAIN_NAME = 'Questionnaires'
export const MEMORY_DOMAIN_NAME = 'Memory'
export const PENDING_SCORE = 'Pending'

/** Prompt */
export const PROMPT_MODIFY = 'modify'
export const PROMPT_NOT_MODIFY = 'not_modify'

/* TASK NAMES */
export const BHS_TASK_NAME = 'Brain Health Survey'
export const GAD7_TASK_NAME = 'Generalized Anxiety Disorder 7-Item'
export const PHQ_TASK_NAME = 'Patient Health Questionnaire'
export const ANIMAL_FLUENCY_TASK_NAME = 'Animal Fluency'
export const FAVORITES_LEARNING_TASK_NAME = 'Favorites Learning'
export const FAVORITES_DELAY_TASK_NAME = 'Favorites Delay'
export const FAVORITES_RECOGNITION_TASK_NAME = 'Favorites Recognition'
export const FAVORITES_TASK_NAME = 'Favorites'
export const DOT_COUNTING_TASK_NAME = 'Dot Counting'
export const MATCH_TASK_NAME = 'Match'
export const RAPID_NAMING_TASK_NAME = 'Rapid Naming'
export const LINE_ORIENTATION_TASK_NAME = 'Line Orientation'
export const LINE_LENGTH_TASK_NAME = 'Line Length'
export const QUICK_TAP_TASK_NAME = 'Quick Tap'
export const TEMPO_TASK_NAME = 'Tempo'
export const RUNNING_DOTS_TASK_NAME = 'Running Dots'
export const SET_SHIFTING_TASK_NAME = 'Set Shifting'
export const FLANKER_TASK_NAME = 'Flanker'
export const BIRDWATCH_TASK_NAME = 'Birdwatch'
export const SIVT_TASK_NAME = 'SIVT'
export const DART_TASK_NAME = 'DART'
export const SDOH_TASK_NAME = 'SDOH'
export const EFS_TASK_NAME = 'Examiner Feedback Survey'
export const SBOCL_TASK_NAME = 'Social Behavior Observer Checklist'
export const NBC_EXPOSURES_TASK_NAME = 'NBC Exposures'
export const COMBAT_EXPOSURE_TASK_NAME = 'Combat Exposure Scale'
export const MINI_COG_TASK_NAME = 'Mini-Cog© Data Entry'

/* TASK IDENTIFIERS */
export const BHS_TASK_IDENTIFIER = 'edu.ucsf.brain-health-survey'
export const GAD7_TASK_IDENTIFIER = 'edu.ucsf.gad-7'
export const PHQ_TASK_IDENTIFIER = 'edu.ucsf.phq'
export const ANIMAL_FLUENCY_TASK_IDENTIFIER = 'edu.ucsf.animal-fluency'
export const FAVORITES_LEARNING_TASK_IDENTIFIER = 'edu.ucsf.favorites-learning'
export const FAVORITES_DELAY_TASK_IDENTIFIER = 'edu.ucsf.favorites-delay'
export const FAVORITES_RECOGNITION_TASK_IDENTIFIER =
  'edu.ucsf.favorites-recognition'
export const FAVORITES_TASK_IDENTIFIER = 'edu.ucsf.favorites'
export const DOT_COUNTING_TASK_IDENTIFIER = 'edu.ucsf.dot-counting'
export const MATCH_TASK_IDENTIFIER = 'edu.ucsf.match'
export const RAPID_NAMING_TASK_IDENTIFIER = 'edu.ucsf.rapid-naming'
export const LINE_ORIENTATION_TASK_IDENTIFIER = 'edu.ucsf.line-orientation'
export const LINE_LENGTH_TASK_IDENTIFIER = 'edu.ucsf.line-length'
export const QUICK_TAP_TASK_IDENTIFIER = 'edu.ucsf.quick-tap'
export const TEMPO_TASK_IDENTIFIER = 'edu.ucsf.tempo'
export const RUNNING_DOTS_TASK_IDENTIFIER = 'edu.ucsf.running-dots'
export const SET_SHIFTING_TASK_IDENTIFIER = 'edu.ucsf.set-shifting'
export const FLANKER_TASK_IDENTIFIER = 'edu.ucsf.flanker'
export const BIRDWATCH_TASK_IDENTIFIER = 'edu.ucsf.birdwatch'
export const SIVT_TASK_IDENTIFIER = 'edu.ucsf.sivt'
export const DART_TASK_IDENTIFIER = 'edu.ucsf.dart'
export const SDOH_TASK_IDENTIFIER = 'edu.ucsf.sdoh'
export const EFS_TASK_IDENTIFIER = 'edu.ucsf.efs'
export const SBOCL_TASK_IDENTIFIER = 'edu.ucsf.sbocl'
export const NBC_EXPOSURES_TASK_IDENTIFIER = 'edu.ucsf.nbc-exposures'
export const COMBAT_EXPOSURE_TASK_IDENTIFIER = 'edu.ucsf.combat-exposure-scale'
export const MSI_TASK_IDENTIFIER = 'edu.ucsf.military-service-information'
export const PCL5_TASK_IDENTIFIER = 'edu.ucsf.pcl-5'
export const OSU_TBI_ID_TASK_IDENTIFIER = 'edu.ucsf.osu-tbi-id'
export const MINI_COG_TASK_IDENTIFIER = 'com.mini-cog.data-entry'

/* TASK BATTERY IDENTIFIERS */
export const TABCAT_BHA_TASK_BATTERY_IDENTIFIER = 'edu.ucsf.bha'

/* TASK RESULTS */
export const TaskResultEvents_BHS = 'TaskResultEvents_BHS'
export const TaskResultEvents_GAD7 = 'TaskResultEvents_GAD7'
export const TaskResultEvents_PHQ = 'TaskResultEvents_PHQ'
export const TaskResultEvents_AnimalFluency = 'TaskResultEvents_AnimalFluency'
export const TaskResultEvents_Favorites = 'TaskResultEvents_Favorites'
export const TaskResultEvents_DotCounting = 'TaskResultEvents_DotCounting'
export const TaskResultEvents_RapidNaming = 'TaskResultEvents_RapidNaming'
export const TaskResultEvents_NBCExposures = 'TaskResultEvents_NBCExposure'
export const TaskResultEvents_CombatExposureScale =
  'TaskResultEvents_CombatExposureScale'
export const TaskResultEvents_EFS = 'TaskResultEvents_EFS'
export const TaskResultEvents_MiniCog = 'TaskResultEvents_MiniCogDataEntry'

/* SCORES NAMES */
export const ECOG_MEAN_NAME = 'BHS_ECogMean'
export const BHS_SUM_NAME = 'BHS_Sum'
export const EFS_COMPLETE = 'EFS_Complete'
export const MSI_COMPLETE = 'MSI_Complete'
export const PCL5_COMPLETE = 'PCL-5_Complete'
export const OSU_TBI_ID_COMPLETE = 'OSU-TBI_Complete'
export const SDOH_CCOMPLETE = 'SDOH_Complete'

/* REPORT FIELD NAMES */
export const FAV_LEARN_R1_TOTAL_CORRECT = 'FavLearn_R1_TotalCorrect'
export const FAV_LEARN_R2_TOTAL_CORRECT = 'FavLearn_R2_TotalCorrect'
export const FAV_DELAY_TOTAL_CORRECT = 'FavDelay_TotalCorrect'
export const FAV_REC_DPRIME = 'FavRec_dPrime'
export const MATCH_TOTAL_CORRECT = 'Match_TotalCorrect'
export const MATCH_TOTAL_INCORRECT = 'Match_TotalIncorrect'
export const LL_THRESHOLD_3_10 = 'LL_ThresholdScore_3-10'
export const LO_THRESHOLD_3_10 = 'LO_ThresholdScore_3-10'
export const LL_CATCH_TRIAL_SCORE = 'LL_CatchTrialScore'
export const LO_CATCH_TRIAL_SCORE = 'LO_CatchTrialScore'
export const ANIMAL_FLUENCY_TOTAL_CORRECT = 'AnimalFluency_TotalCorrect'
export const ANIMAL_FLUENCY_TOTAL_REPETITIONS = 'AnimalFluency_TotalRepetitions'
export const ANIMAL_FLUENCY_TOTAL_RULE_VIOLATIONS =
  'AnimalFluency_TotalRuleViolations'
export const BHS_ECOG_MEAN = 'BHS_ECogMean'
export const BHS_SUM = 'BHS_Sum'
export const FAV_REC_TOTAL_INCORRECT = 'FavRec_TotalIncorrect'
export const FAV_REC_TOTAL = 'FavRec_Total'
export const FAV_REC_HITS_FPS = 'FavRec_TotalRecognitionsHitsFPs'
export const FAV_REC_BIAS = 'FavRec_Bias'
export const FAV_ALLTRIALS_TOTAL_CORRECT = 'Favorites_AllTrials_TotalCorrect'
export const FAV_REC_TOTAL_TRUE_POSITIVE = 'FavRec_TotalTruePositive'
export const FAV_REC_TOTAL_FALSE_POSITIVE = 'FavRec_TotalFalsePositive'
export const DOT_COUNTING_TOTAL = 'DotCounting_TotalScore'
export const RAPID_NAMING_TOTAL_CORRECT = 'RapidNaming_TotalCorrect'
export const RAPID_NAMING_TOTAL_INCORRECT = 'RapidNaming_TotalIncorrect'
export const RAPID_NAMING_TOTAL_SKIPPED = 'RapidNaming_TotalSkipped'
export const RAPID_NAMING_AVG_REACTION_TIME = 'RapidNaming_AvgReactionTime'
export const RUNNING_DOTS_TOTAL_TRIAL = 'RunningDots_TotalTrialScore'
export const PHQ_TOTAL_SCORE = 'PHQ_TotalScore'
export const GAD7_TOTAL_SCORE = 'GAD7_TotalScore'
export const SET_SHIFTING_TOTAL_SCORE = 'SetShifting_TotalScore'
export const FLANKER_TOTAL_SCORE = 'Flanker_TotalScore'
export const QUICKTAP_INT_PRECISION = 'QuickTap_IntPrecision'
export const SBOCL_DISORGANIZED_CLUSTER = 'SBOCL_Disorganized_Cluster'
export const SBOCL_INSENSITIVE_CLUSTER = 'SBOCL_Insensitive_Cluster'
export const SBOCL_REACTIVE_CLUSTER = 'SBOCL_Reactive_Cluster'
export const CES_TOTAL_SCORE = 'Combat_Exposure_Scale_Total_Score'
export const MINI_COG_TOTAL_SCORE = 'Mini_Cog_Data_Entry_Total_Score'

/** Composite Scores */
export const COG_SCORE_FULL = 'cogScoreFull'
export const COG_SCORE_SHORT = 'cogScoreShort'
export const ESTIMATED_MO_CA_SHORT = 'estimatedMoCAShort'
export const ESTIMATED_MO_CA_FULL = 'estimatedMoCAFull'
export const ESTIMATED_MM_SE_FULL = 'estimatedMMSEFull'
export const ESTIMATED_MM_SE_SHORT = 'estimatedMMSEShort'
export const GLOBAL_SCORE_FULL = 'globalScoreFull'
export const GLOBAL_SCORE_SHORT = 'globalScoreShort'

/** DROPDOWN OPTIONS (STUDIES) */
export const phiOptions = [
  {
    label: 'Limited Demographics (restricted PHI)',
    value: LIMITED_DEMOGRAPHICS
  },
  {
    label: 'Full Demographics (with PHI)',
    value: FULL_DEMOGRAPHICS
  }
]
export const SUMMARY_REPORT_TITLE =
  'TabCAT Brain Health Assessment Summary Report'
export const SUMMARY_ABBREVIATED_REPORT_TITLE =
  'TabCAT Brain Health Assessment Summary Report: Abbreviated'
export const KPSC_REPORT_TITLE =
  'TabCAT Brain Health Assessment Summary Report: KPSC'

export const reportOptions = [
  {
    title: SUMMARY_REPORT_TITLE,
    description: `This Summary Report provides a text-based interpretation of the examinee’s
     performance along with care guidance. Required tasks are Favorites Learning, 
     Favorites Delay, and Match. Optional tasks are Animal Fluency, Brain Health Survey, 
     and Line Orientation. The Report is available for download on the Admin Website and is integrated with 
     UCSF’s EMR.`
  },
  {
    title: SUMMARY_ABBREVIATED_REPORT_TITLE,
    description: `This Summary Report provides a text-based interpretation of the examinee’s performance. 
    Required tasks are Favorites Learning, Favorites Delay, and Match. Optional tasks are Animal Fluency, 
    Brain Health Survey, and Line Orientation. The Report is available for download on the Admin Website.`
  },
  {
    title: KPSC_REPORT_TITLE,
    description: 'This Summary Report provides a text-based interpretation of the examinee’s performance along with care guidance specific to Kaiser Permanente - Southern California region (KPSC). Required tasks are Favorites Learning, Favorites Delay, and Match. Optional tasks are Animal Fluency, Brain Health Survey, and Line Orientation. The Report is available for download on the Admin Website and is integrated with KPSC’s EMR.'
  }
]

/** ROLES */
export const SYSTEM_ADMIN = 'System Admin'
export const REALM_ADMIN = 'Realm Admin'
export const DATA_ADMIN = 'Data Admin'
export const RATER = 'Rater'
export const DEVELOPER = 'Developer'
export const EMR = 'EMR'

/** OTHER CONSTANTS */
export const ACTIVATE_USER = 'Activate User'
export const SITES_AND_USERS = 'sites & users'
export const TASKS = 'tasks'
export const UNAUTHENTICATED = 'invalid-jwt'
export const ALL_OPTIONS = 'All'
export const COMPOSITE_SCORES_OPTION = 'composite-scores'
export const GUIDES = 'Task Guides'
export const ALL_REALMS = 'All Realms'
export const ALL_REALMS_ID = -1

export const navRealmPages = ['Studies', 'Sites', 'Users']

/** LANGUAGES */
export const testingLanguages = {
  af: 'Afrikaans – Afrikaans',
  am: 'Amharic – አማርኛ',
  ar: 'Arabic – العربية',
  bn: 'Bangla – বাংলা',
  bg: 'Bulgarian – български',
  bo: 'Tibetan – བོད་སྐད',
  ca: 'Catalan – català',
  cs: 'Czech – čeština',
  cy: 'Welsh – Cymraeg',
  da: 'Danish – dansk',
  de: 'German – Deutsch',
  el: 'Greek – Ελληνικά',
  en: 'English',
  es: 'Spanish – español (MEX, ESP, Central America)',
  'es-ar': 'Spanish – español (Argentina, Uruguay)',
  'es-cu': 'Spanish – español (Cuba)',
  et: 'Estonian – eesti',
  eu: 'Basque – euskara',
  fa: 'Persian – فارسی',
  fi: 'Finnish – suomi',
  fil: 'Filipino – Filipino',
  fr: 'French – français',
  ga: 'Irish – Gaeilge',
  gu: 'Gujarati – ગુજરાતી',
  ha: 'Hausa – Hausa',
  he: 'Hebrew – עברית',
  hi: 'Hindi – हिन्दी',
  hr: 'Croatian – hrvatski',
  hu: 'Hungarian – magyar',
  hy: 'Armenian – հայերեն',
  id: 'Indonesian – Indonesia',
  ig: 'Igbo – Igbo',
  is: 'Icelandic – íslenska',
  it: 'Italian – italiano',
  ja: 'Japanese – 日本語',
  jv: 'Javanese – Jawa',
  km: 'Khmer – ខ្មែរ',
  ko: 'Korean – 한국어',
  la: 'Latin – Latin',
  lt: 'Lithuanian – lietuvių',
  lv: 'Latvian – latviešu',
  mk: 'Macedonian – македонски',
  mn: 'Mongolian – монгол',
  mr: 'Marathi – मराठी',
  ms: 'Malay – Bahasa Melayu',
  ne: 'Nepali – नेपाली',
  nl: 'Dutch – Nederlands',
  nb: 'Norwegian Bokmål – norsk bokmål',
  nv: 'Navajo – Diné bizaad',
  nyn: 'Nyankole – Runyankore',
  pa: 'Punjabi – ਪੰਜਾਬੀ',
  pl: 'Polish – polski',
  pt: 'Portuguese (Portugal) – português (Portugal)',
  'pt-br': 'Portuguese – português (Brasil)',
  qu: 'Quechua – Runasimi',
  ro: 'Romanian – română',
  ru: 'Russian – русский',
  sk: 'Slovak – slovenčina',
  sl: 'Slovenian – slovenščina',
  sm: 'Samoan – Samoan',
  sq: 'Albanian – shqip',
  sr: 'Serbian – српски',
  sv: 'Swedish – svenska',
  sw: 'Swahili – Kiswahili',
  ta: 'Tamil – தமிழ்',
  te: 'Telugu – తెలుగు',
  th: 'Thai – ไทย',
  tn: 'Tswana – Setswana',
  to: 'Tongan – lea fakatonga',
  tr: 'Turkish – Türkçe',
  uk: 'Ukrainian – українська',
  ur: 'Urdu – اردو',
  vi: 'Vietnamese – Tiếng Việt',
  wuu: 'Shanghainese – 上海话',
  yi: 'Yiddish – ייִדיש',
  yue: 'Cantonese – 廣東話',
  'zh-hans': 'Mandarin, Simplified – 简体中文',
  'zh-hant': 'Mandarin, Traditional – 繁體中文'
}

/** TASK ARRAYS */
export const BHA_SUB_TASKS = [
  'edu.ucsf.birdwatch',
  'edu.ucsf.match',
  'edu.ucsf.efs'
]

export const nativeLanguageOptions = [
  'Afrikanns',
  'Albanian',
  'Amharic',
  'Arabic',
  'Armenian',
  'Bangla',
  'Basque',
  'Bulgarian',
  //'Cambodian',
  'Cantonese',
  'Catalan',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Filipino',
  'Finnish',
  'French',
  'German',
  'Greek',
  'Gujarati',
  'Hausa',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Khmer',
  'Korean',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Malay',
  'Mandarin (Simplified)',
  'Mandarin (Traditional)',
  'Marathi',
  'Mongolian',
  'Navajo',
  'Nepali',
  'Norwegian Bokmål',
  'Persian',
  'Polish',
  'Portuguese (Brazil)',
  'Portuguese (Portugal)',
  'Punjabi',
  'Quechua',
  'Romanian',
  'Runyankore',
  'Russian',
  'Samoan',
  'Serbian',
  'Setswana',
  'Shanghainese',
  'Slovak',
  'Slovenian',
  'Spanish (Argentina, Uruguay)',
  'Spanish (Cuba)',
  'Spanish (MEX, ESP, Central America)',
  'Swahili',
  'Swedish',
  //'Tagalog',
  'Tamil',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tongan',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Welsh',
  'Yiddish',
  'Other'
]

/** DATA VALIDITY ISSUES */
export const dataValidityIssues = [
  'Examinee Factor',
  'Environmental Factor',
  'Technical/Administration Factor',
  'None'
]

/** TABS */
export const encounterTabs = [
  'Encounter',
  'Examinee',
  'Informant',
  'Scores',
  'Reports'
]
export const studyTabs = ['Sites & Users', 'Tasks', 'Reports']

/** Disclaimers */
export const disclaimerPrefixes = ['regDisclaimer', 'tradDisclaimer']

export const HASURA_CLAIMS_KEY = 'https://hasura.io/jwt/claims'
export const HASURA_ALLOWED_ROLES_KEY = 'x-hasura-allowed-roles'
