import gql from 'graphql-tag'

export const GET_ALL_REALMS_QUERY = gql`
  query getAllRealms {
    Realm(order_by: { name: asc }) {
      id
      name
      isBlocked
      Sites_aggregate(where: { isBlocked: { _eq: false } }) {
        aggregate {
          count
        }
      }
      Studies_aggregate(where: { isBlocked: { _eq: false } }) {
        aggregate {
          count
        }
      }
      Realm_Users_aggregate(
        where: { User: { isDisplayBlocked: { _eq: false } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
