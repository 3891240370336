import gql from 'graphql-tag'

export const REMOVE_USER_FROM_SITE_MUTATION = gql`
  mutation removeUserFromSite($siteId: Int!, $userId: String!) {
    delete_Site_User(
      where: { site_id: { _eq: $siteId }, user_id: { _eq: $userId } }
    ) {
      returning {
        Site {
          id
        }
        User {
          familyName
          givenName
          email
          createdAt
          id
          name
          isDisplayBlocked
          Site_Users_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
