import { Button, Pane, SelectMenu } from 'evergreen-ui'
import { navigate } from 'gatsby'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import myTheme, { breakpoints } from '../../styles/theme'
import { navRealmPages } from '../../utils/constants'
import ResourcesDesktop from './ResourcesDesktop'
import { getRealmIdFromURL } from '../../utils/filters'
import { useRoleAccess } from '../../hooks/useRoleAccess'

const useStyles = createUseStyles({
  navButtons: {
    background: `${myTheme.colors.blue} !important`,
    border: 'none',
    boxShadow: 'none',
    fontFamily: 'Roboto',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: '30px',
    backgroundColor: `${myTheme.colors.blue} !important`,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      boxShadow: 'none !important',
      color: '#ffffff',
      border: `1px solid #ffffff`
    },
    [`@media (max-width:${breakpoints.md})`]: {
      fontSize: '14px',
      marginRight: '0px !important',
      marginTop: '5px',
      marginBottom: '5px',
      backgroundColor: 'transparent',
      color: myTheme.colors.labelDark,
      fontWeight: '500',
      margin: 0,
      padding: 0
    }
  },
  optionsDesktopWrapper: {
    display: 'flex',
    [`@media (max-width:${breakpoints.md})`]: {
      display: 'none'
    }
  },
  profileNav: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${myTheme.colors.borderDark}`,
    padding: '0px 15px 0px 15px'
  }
})

const HeaderOptionsDesktop = ({
  handleRealmResourceChange,
  handleOpenUserDetails,
  handleLogout
}) => {
  const classes = useStyles()
  const { userData, realmId } = useGlobalStore()
  const { canExportData } = useRoleAccess()
  const routeRealm = window?.location
    ? Number(getRealmIdFromURL(window.location))
    : -1

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  return (
    <>
      <Pane className={classes.optionsDesktopWrapper}>
        <Pane flex={1} alignSelf="center" textAlign="right">
          {/* Here are the list of pages under the button "Realm" */}
          <SelectMenu
            options={navRealmPages.map(label => ({
              label,
              value: label
            }))}
            selected={''}
            onSelect={item => handleRealmResourceChange(item, realRealmId)}
            hasFilter={false}
            hasTitle={false}
            closeOnSelect={true}
            width={180}
            height={180}
          >
            <Button className={classes.navButtons}>Realm</Button>
          </SelectMenu>

          {canExportData && (
              <Button
              className={classes.navButtons}
              onClick={() => navigate(`/realm/${realRealmId}/data-export`)}
            >
              Data Export
            </Button>
            )}
          {realRealmId > 0 && (
            <Button
              className={classes.navButtons}
              onClick={() => navigate(`/realm/${realRealmId}/encounters`)}
            >
              Encounters
            </Button>
          )}
          <ResourcesDesktop
            handleRealmResourceChange={handleRealmResourceChange}
          />
        </Pane>
        <Pane className={classes.profileNav}>
          <Button
            className={classes.navButtons}
            onClick={handleOpenUserDetails}
            disabled={!userData}
          >
            {`${userData?.givenName || ''} ${userData?.familyName || ''}`}
          </Button>

          <Button
            href="/"
            onClick={handleLogout}
            className={classes.navButtons}
          >
            Log Out
          </Button>
        </Pane>
      </Pane>
    </>
  )
}

export default HeaderOptionsDesktop
