import { TextareaField } from 'evergreen-ui'
import React from 'react'
import { useController } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { formStyles } from '../../styles/sharedComponentStyles'
import { getError } from './helpers'

const useSharedStyles = createUseStyles({
  ...formStyles
})

const TextAreaField = ({
  label,
  name,
  disabled,
  defaultValue,
  isInvalid,
  control,
  inputHeight,
  showErrorMessage,
  required,
  ...rest
}) => {
  const { field, fieldState } = useController({ control, name, defaultValue })
  const { hasError, errorMessage } = getError(fieldState)
  const sharedClasses = useSharedStyles()
  return (
    <TextareaField
      {...field}
      name={name}
      label={label}
      validationMessage={showErrorMessage ? errorMessage : undefined}
      required={required}
      disabled={disabled}
      inputHeight={inputHeight}
      onChange={e => field.onChange(e.target.value)}
      style={{
        padding: '20px'
      }}
      className={sharedClasses.roundedInput}
      isInvalid={hasError}
      {...rest}
    />
  )
}

export default TextAreaField
