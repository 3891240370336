import gql from 'graphql-tag'

export const GET_TERMS_AND_CONDITIONS_QUERY = gql`
  query TermsAndConditionsDocument($id: String!, $preview: Boolean) {
    document(id: $id, preview: $preview) {
      __typename
      title
      content {
        __typename
        json
      }
      sys {
        __typename
        publishedAt
        publishedVersion
      }
    }
  }
`
