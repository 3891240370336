import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { useEffect } from 'react'
import { GET_REALM_USER_BY_ID_QUERY } from '../queries/getRealmUserById'
import { getProfile } from '../utils/auth'
import { useGlobalStore } from './useGlobalStore'

const GET_USER_DATA_ERROR_MESSAGE =
  'Sorry, something went wrong getting the current user data data, please try again later.'

export function useSetGlobalUserData() {
  const user = getProfile()
  const { setUserData } = useGlobalStore()

  const { loading: userDataLoading, data: primaryUserData } = useQuery(
    GET_REALM_USER_BY_ID_QUERY,
    {
      skip: !user?.sub,
      variables: {
        userId: user.sub
      },
      onError: _ => toaster.danger(GET_USER_DATA_ERROR_MESSAGE)
    }
  )

  useEffect(() => {
    /** set the user data */
    function onUserQuerySuccess(result) {
      if (result?.User_by_pk) {
        setUserData(result.User_by_pk)
      }
    }
    onUserQuerySuccess(primaryUserData)
  }, [setUserData, primaryUserData])

  return { userDataLoading }
}
