import React from 'react'
import { Button, Heading, Pane } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { useHandleAccessManagement } from '../../hooks/useHandleAccessManagement'
import {
  customListStyles,
  formStyles
} from '../../styles/sharedComponentStyles'
import myTheme from '../../styles/theme'
import Modal from '../Modal'
import RealmList from '../RealmList'
import SiteStudyList from '../SiteStudyList'
import Stepper from '../Stepper'

const useSharedStyles = createUseStyles({
  ...formStyles,
  ...customListStyles,
  wrapper: {
    width: '100%',
    padding: '20px',
    textAlign: 'left'
  },
  fields: {
    composes: '$fieldsLabel',
    width: '100%',
    '& p': {
      color: `${myTheme.colors.grey}`
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  },
  buttonsSites: {
    composes: '$buttons',
    justifyContent: 'space-between'
  }
})

const AccessManagement = ({ user, showModal, handleCloseModal }) => {
  const sharedClasses = useSharedStyles()
  const {
    control,
    updateLoading,
    handleFormSubmit,
    handleGoToSelectRealms,
    handleGoToSelectSites,
    shouldRequestRealms,
    step,
    role,
    realmsSelected,
    currentRealms,
    currentSites,
    watch,
    disableNextRealmSelection,
    isSystemAdmin
  } = useHandleAccessManagement({ user, handleCloseModal })

  return (
    <Modal
      width={900}
      showDialog={showModal}
      handleDialogClose={handleCloseModal}
    >
      <Modal.Header title="Manage Access" handleCloseModal={handleCloseModal} />
      <Modal.Body>
        <Pane className={sharedClasses.wrapper}>
          <Pane className={sharedClasses.fields}>
            <Heading size={600}>{`User: ${user.familyName || ''} ${
              user.givenName || ''
            }, ${role}`}</Heading>
            {step === 'select_realms' && (
              <RealmSelection
                control={control}
                handleGoToSelectSites={handleGoToSelectSites}
                role={role}
                currentRealms={currentRealms}
                shouldRequest={shouldRequestRealms}
                updateLoading={updateLoading}
                disableNext={disableNextRealmSelection}
              />
            )}
            {step === 'select_sites' && (
              <Stepper
                steps={realmsSelected.map(r => r.name)}
                onConfirm={handleFormSubmit}
                onBack={handleGoToSelectRealms}
                withoutProgress
              >
                {realmsSelected.map(realm => {
                  const currentSitesSelected =
                    watch(`sites_${realm.id}`)?.filter(s => s.value) || []
                  const disableNext =
                    !isSystemAdmin && currentSitesSelected.length <= 0
                  return (
                    <Stepper.Step
                      key={realm.id}
                      disableBack={false}
                      disableNext={disableNext}
                      nextLoading={updateLoading}
                    >
                      <SiteStudySelection
                        control={control}
                        role={role}
                        realm={realm}
                        currentSites={currentSites}
                      />
                    </Stepper.Step>
                  )
                })}
              </Stepper>
            )}
          </Pane>
        </Pane>
      </Modal.Body>
    </Modal>
  )
}

const RealmSelection = ({
  currentRealms,
  handleGoToSelectSites,
  control,
  shouldRequest,
  role,
  disableNext = false,
  updateLoading
}) => {
  const sharedClasses = useSharedStyles()
  return (
    <>
      <RealmList
        control={control}
        role={role}
        currentRealms={currentRealms}
        shouldRequest={shouldRequest}
      />
      <Pane className={sharedClasses.buttons}>
        <Button
          className={sharedClasses.buttonStyles}
          isLoading={updateLoading}
          onClick={handleGoToSelectSites}
          disabled={disableNext}
        >
          Next
        </Button>
      </Pane>
    </>
  )
}

const SiteStudySelection = ({ control, realm, currentSites, role }) => {
  return (
    <Pane textAlign="left">
      <p>Realm: {realm?.name}</p>
      <SiteStudyList
        control={control}
        role={role}
        realmId={realm?.id}
        currentSites={currentSites}
      />
    </Pane>
  )
}

export default AccessManagement
