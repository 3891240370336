import gql from 'graphql-tag'

export const GET_SITES_QUERY = gql`
  query SiteQuery($siteWhere: Site_bool_exp) {
    Site(where: $siteWhere, order_by: { name: asc }) {
      name
      notes
      isBlocked
      infoDescription
      id
      Study_Sites(
        order_by: { Study: { name: asc } }
        where: { Study: { isBlocked: { _eq: false } } }
      ) {
        Study {
          id
          name
        }
      }
      Study_Sites_aggregate(where: { Study: { isBlocked: { _eq: false } } }) {
        aggregate {
          count
        }
      }
      Site_Users_aggregate(
        where: { User: { isDisplayBlocked: { _eq: false } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
