import {
  beforeUnloadDataStore,
  contentfulDataStore,
  realmDataStore,
  sideSheetDataStore,
  userDataStore
} from '../stores/globalStore'

export const useGlobalStore = () => {
  /** name of the current realm */
  //const currentRealm = realmDataStore(state => state.currentRealm)
  const realmData = realmDataStore(
    state => state.realmData
  ) /** array with all the realms */
  const realmId = realmDataStore(state => state.realmId)
  const setRealmId = realmDataStore(state => state.setRealmId)
  const realmPath = realmDataStore(state => state.realmPath)
  const setRealmPath = realmDataStore(state => state.setRealmPath)
  const encounterId = realmDataStore(state => state.encounterId)
  const setRealmData = realmDataStore(state => state.setRealmData)
  //const setCurrentRealm = realmDataStore(state => state.setCurrentRealm)
  const setEncounterId = realmDataStore(state => state.setEncounterId)
  const userData = userDataStore(state => state.userData)
  const setUserData = userDataStore(state => state.setUserData)
  const showPrompt = beforeUnloadDataStore(state => state.showPrompt)
  const isEditing = beforeUnloadDataStore(state => state.isEditing)
  const setShowPrompt = beforeUnloadDataStore(state => state.setShowPrompt)
  const isPreview = contentfulDataStore(state => state.isPreview)
  const shouldCloseSideSheet = sideSheetDataStore(
    state => state.shouldCloseSideSheet
  )
  const setShouldCloseSideSheet = sideSheetDataStore(
    state => state.setShouldCloseSideSheet
  )
  const sideSheetIsOpen = sideSheetDataStore(state => state.sideSheetIsOpen)
  const setSideSheetIsOpen = sideSheetDataStore(
    state => state.setSideSheetIsOpen
  )

  return {
    //currentRealm,
    realmPath,
    setRealmPath,
    realmData,
    realmId,
    encounterId,
    setRealmData,
    //setCurrentRealm,
    setRealmId,
    setEncounterId,
    userData,
    setUserData,
    showPrompt,
    isEditing,
    setShowPrompt,
    isPreview,
    shouldCloseSideSheet,
    setShouldCloseSideSheet,
    sideSheetIsOpen,
    setSideSheetIsOpen
  }
}
